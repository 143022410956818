import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import DrawerGen from '../drawerGen/index';

function DrawerAccountsSubscription({
  isOpen,
  onClose,
  btnRef,
  data,
}: Pick<any, 'isOpen' | 'onClose' | 'btnRef'> & {
  data: { bankDenomination: string; accounts: { accountType: string; accountCurrency: string; accountCbu: string }[] };
}) {
  return (
    <DrawerGen
      title={data.bankDenomination}
      description={`${data.accounts.length} cuentas seleccionadas`}
      isOpen={isOpen}
      btnRef={btnRef}
      onClose={onClose}
    >
      <TableContainer style={{ overflow: 'auto', height: '90vh' }}>
        <Table variant='unstyled'>
          <Thead className='acco'>
            <Tr>
              <Th className='header-tab'>Tipo de cuenta</Th>
              <Th className='header-tab'>Moneda</Th>
              <Th className='header-tab'>CBU</Th>
            </Tr>
          </Thead>

          {data.accounts.map((account: any) => (
            <Tbody key={account.accountCbu}>
              <Tr>
                <Td>{`${account.accountType === 'CC' ? 'Cuenta corriente' : 'Caja de ahorro'}`}</Td>
                <Td>{`${account.accountCurrency === '$' ? 'ARS' : 'USD'}`}</Td>
                <Td>{account.accountCbu}</Td>
              </Tr>
            </Tbody>
          ))}
        </Table>
      </TableContainer>
    </DrawerGen>
  );
}

export default DrawerAccountsSubscription;
