import { Box, Skeleton, Stack } from '@chakra-ui/react';

function Loader() {
  return (
    <Box padding='6' bg='white'>
      <Stack>
        <Skeleton height='20px' data-testid='skeleton-1' />
        <Skeleton height='20px' data-testid='skeleton-2' />
        <Skeleton height='20px' data-testid='skeleton-3' />
        <Skeleton mt='2' height='170px' data-testid='skeleton-4' />
      </Stack>
    </Box>
  );
}

export default Loader;
