import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Text } from '@chakra-ui/react';

import { DrawerGenInterface } from './DrawerGenInterface';
import DrawerPanel from './styles';

function DrawerGen({ title, description, isOpen, onClose, btnRef, children }: DrawerGenInterface) {
  return (
    <Drawer size='lg' isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef}>
      <DrawerOverlay />

      <DrawerPanel>
        <DrawerContent pl='20px'>
          <DrawerCloseButton />

          <Text ml='15px' mt='20px' mb='0px' id='title' as='h1' textStyle='h4Bold'>
            {title}
          </Text>

          <Text ml='15px' mt='0px' id='description' textStyle='bodyMdSemi'>
            {description}
          </Text>
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </DrawerPanel>
    </Drawer>
  );
}

export default DrawerGen;
