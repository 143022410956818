import { Box, Button, Checkbox, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { Tag } from '@wow/tag';
import { useMemo, useState } from 'react';

import close from '../../assets/img/close.svg';
import { Bank } from '../../Interfaces/getBank';
import Search from '../btns/search';
import FilterPanel from './styles';

interface FilterTagProps {
  banks: Bank[];
  disabled?: boolean;
  onChange: (...args: any[]) => void;
}

function FilterTag({ onChange, banks, disabled }: FilterTagProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [search, setSearch] = useState<string>('');

  const count = useMemo(() => Object.keys(selectedItems).length, [selectedItems]);

  const handleClickOpen = () => setIsOpen(prevIsOpen => !prevIsOpen);

  const handleCancelButtonClick = () => {
    onChange(new Set());
    setSelectedItems({});
    setIsOpen(false);
    setSearch('');
  };

  const handleCheckboxChange = (bank: Bank) => {
    setSelectedItems(prevSelectedItems => ({
      ...prevSelectedItems,
      [bank.denominacion]: !prevSelectedItems[bank.denominacion],
    }));
  };

  const handleSearchInputChange = event => {
    setSearch(event.target.value);
  };

  const handleApplyButtonClick = () => {
    const selectedBanks = Object.entries(selectedItems).reduce((acc, [key, value]) => {
      if (value) acc.add(key);

      return acc;
    }, new Set<string>());

    onChange(selectedBanks);
    setIsOpen(false);
    setSearch('');
  };

  const filteredBanks = useMemo(() => {
    const normalizedSearch = search.toLowerCase();

    return banks.filter(item => item.denominacion.toLowerCase().includes(normalizedSearch));
  }, [banks, search, selectedItems]);

  return (
    <FilterPanel>
      <div className='filter-containerDNBA'>
        <span className='spani-DNBA pd-top'>Filtrá por:</span>
        <div className='mr-left pd-top'>
          <Tag
            isDisabled={disabled}
            component='filter'
            onClick={handleClickOpen}
            count={count}
            isSelected={count > 0 && !isOpen}
            isOpen={isOpen}
            label='Banco'
          />

          {isOpen && (
            <div className={filteredBanks.length === 0 ? 'modal-content modal-height' : 'modal-content'}>
              <div className='title-content'>
                <h1 className='style-title'>Buscar por banco</h1>
                <button className='close-button' onClick={handleCancelButtonClick}>
                  <img className='close-button' src={close} alt='close' />
                </button>
              </div>

              <div
                style={{
                  width: '100%',
                  padding: '5px 0 0 0',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  display: 'inline-flex',
                }}
              >
                <div style={{ flex: '1 1 0', height: 1, background: 'rgba(0, 0, 0, 0.24)' }} />
              </div>

              <div className='scroll'>
                <div className='input-content'>
                  <InputGroup display='flex' flexWrap='wrap'>
                    <InputLeftElement pointerEvents='none'>
                      <Search />
                    </InputLeftElement>
                    <Input onChange={handleSearchInputChange} id='search-bank' name='bank' placeholder='Buscar' />
                    <p className='label-style'>Ingresá las primeras letras del banco</p>
                  </InputGroup>
                </div>
                <Box className='container-checkbox-DNBA'>
                  {filteredBanks.length === 0 ? (
                    <div className='checkbox-content'>
                      <p className='not-found-text'>No hay resultados</p>
                    </div>
                  ) : (
                    filteredBanks.map(bank => (
                      <div className='checkbox-content' key={bank.denominacion}>
                        <Checkbox onChange={() => handleCheckboxChange(bank)} isChecked={!!selectedItems[bank.denominacion]}>
                          {bank.denominacion}
                        </Checkbox>
                      </div>
                    ))
                  )}
                </Box>
              </div>
              <footer className='footer'>
                <div
                  style={{
                    width: '100%',
                    padding: '20px 0 5px 0',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'inline-flex',
                  }}
                >
                  <div style={{ flex: '1 1 0', height: 1, background: 'rgba(0, 0, 0, 0.24)' }} />
                </div>
                <div className='display-flex pd'>
                  <Button className='button-text-blue' onClick={handleCancelButtonClick} variant='tertiary-text'>
                    Borrar
                  </Button>
                  <Button className='button-text-white' variant='primary' onClick={handleApplyButtonClick}>
                    Aplicar
                  </Button>
                </div>
              </footer>
            </div>
          )}
        </div>
        <div>
          <Button isDisabled={disabled} className='button-text-blue' onClick={handleCancelButtonClick} variant='tertiary-text'>
            Borrar Filtros
          </Button>
        </div>
      </div>
    </FilterPanel>
  );
}

export default FilterTag;
