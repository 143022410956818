import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { Account } from '../../Interfaces/getBank';
import { DrawerGenInterface } from '../drawerGen/DrawerGenInterface';
import DrawerGen from '../drawerGen/index';

function DrawerAccounts({
  isOpen,
  onClose,
  btnRef,
  data,
}: Pick<DrawerGenInterface, 'isOpen' | 'onClose' | 'btnRef'> & {
  data: { denominacion: string; accounts: { tipo: string; moneda: string; cbu: string }[] };
}) {
  return (
    <DrawerGen
      title={data.denominacion}
      description={`${data.accounts.length} cuentas seleccionadas`}
      isOpen={isOpen}
      btnRef={btnRef}
      onClose={onClose}
    >
      <TableContainer style={{ overflow: 'auto', height: '90vh' }}>
        <Table variant='unstyled'>
          <Thead className='acco'>
            <Tr>
              <Th className='header-tab'>Tipo de cuenta</Th>
              <Th className='header-tab'>Moneda</Th>
              <Th className='header-tab'>CBU</Th>
            </Tr>
          </Thead>

          {data.accounts.map((account: Account) => (
            <Tbody key={account.cbu}>
              <Tr>
                <Td>{`${account.tipo === 'CC' ? 'Cuenta corriente' : 'Caja de ahorro'}`}</Td>
                <Td>{`${account.moneda === '$' ? 'ARS' : 'USD'}`}</Td>
                <Td>{account.cbu}</Td>
              </Tr>
            </Tbody>
          ))}
        </Table>
      </TableContainer>
    </DrawerGen>
  );
}

export default DrawerAccounts;
