import DOMPurify from 'dompurify';

const WizardSteps = [
  {
    title: '¡Digitalizamos el alta de bancos!',
    description: '¡Nos agilizamos! Ahora podés agregar a la mayoría de tus bancos sin tener que ir a una sucursal.',
    target: 'title-digital',
    placement: 'top',
    offsetExtraY: 0,
    offsetExtraX: 0,
    enableScroll: false,
  },
  {
    title: 'Creá una solicitud de alta',
    description: 'Deberá ser confirmada por quienes representan a tu empresa y luego aprobada por los bancos.',
    target: 'agregar-bancos',
    placement: 'right',
    offsetExtraY: 0,
    offsetExtraX: 0,
    enableScroll: false,
  },
  {
    title: 'Consultá tus solicitudes',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Podés seguir las que están curso de aprobación. Cuando finalicen, pasarán a la sección  <strong>Plan contratado. </strong>',
          ),
        }}
      />
    ),
    target: 'applications-accordion',
    placement: 'bottom',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
  {
    title: 'Representantes legales / personas apoderadas',
    description:
      'Son quienes tienen las facultades de contratar servicios en la empresa y deben figurar con este mismo rol en el banco. Confirmarán tus altas.',
    target: 'legal-representative',
    placement: 'bottom',
    offsetExtraY: 20,
    offsetExtraX: 0,
  },
  {
    title: 'Bancos con suscripción física',
    description: 'En algunos casos deberás descargar los formularios y presentarlos en una sucursal para finalizar el alta.',
    target: 'download-forms',
    placement: 'right',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
  {
    title: 'Agregá todos tus bancos',
    description: 'Si no tenés solicitudes, ya podés comenzar. ¡Asociá nuevos bancos a tu empresa y simplificá tu operatoria!',
    target: 'mainBox',
    placement: 'top',
    offsetExtraY: 0,
    offsetExtraX: 0,
  },
];
export default WizardSteps;
