import { PendingRequests } from '../Interfaces/pendingRequests';

/* eslint-disable import/prefer-default-export */
export const applicationServiceMockNew: PendingRequests = {
  solicitudes: [
    {
      banks: [
        {
          accounts: [
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '0070918611000103749988',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
          ],
          bankCodeBcra: null,
          bankDenomination: 'Banco 1',
          bankId: 36063,
          bankStatus: 'STORED',
          digitalSubscription: false,
        },
        {
          accounts: [
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '0070918611000103749988',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
          ],
          bankCodeBcra: null,
          bankDenomination: 'Banco 2',
          bankId: 36063,
          bankStatus: 'STORED',
          digitalSubscription: true,
        },
        {
          accounts: [
            {
              accountCbu: '8888918611000103741057',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
            {
              accountCbu: '0070918611000103749988',
              accountCurrency: '$',
              accountType: 'CC',
              ownership: 'OWN',
            },
          ],
          bankCodeBcra: null,
          bankDenomination: 'Banco 3',
          bankId: 36064,
          bankStatus: 'STORED',
          digitalSubscription: true,
        },
      ],
      company: {
        clientId: 564,
        companyCodeClient: 'P05892A',
        companyName: 'Hecho a mano S.A.',
        cuit: '24210145741',
        entityId: 20589200,
      },
      createdAt: '2023-09-23T11:20:00-0300',
      documentsGroup: {
        documentGroupId: '65973c92-7c92-469a-a231-ae608147003f',
        documents: [
          {
            documentCode: 'TYCP',
            documentId: '3f5496a1-ca6f-430b-8326-1322bb88f6c7',
            legalDocumentType: 'TYCP_FRE',
          },
        ],
      },
      legalRepresentatives: [
        {
          confirmationStatus: 'PROCESS_STARTED',
          countryCode: 'AR',
          documentNumber: '20407169639',
          documentType: 'CUIL',
          emailAddress: 'susanalopezpaz@interbanking.com.ar',
          id: 1,
          lastname: 'Lopez Paz',
          name: 'Susana',
        },
        {
          confirmationStatus: 'PROCESS_STARTED',
          countryCode: 'AR',
          documentNumber: '20331342646',
          documentType: 'CUIL',
          emailAddress: 'marlomartinez@interbanking.com.ar',
          id: 2,
          lastname: 'Martinez',
          name: 'Marlo',
        },
      ],
      offering: {
        codDn: 'FRE',
        denomin: 'Gratuito',
        id: 60,
      },
      operator: {
        email: 'gjeanplong@interbanking.com.ar',
        lastName: ' Lucena',
        name: ' Rosario',
        operatorId: 20589201,
      },
      requestId: 'eeae4345-2342-471b-b38d-b089d155ca88',
      status: 'TYC_STORED',
      updatedAt: '2023-09-23T11:20:00-0300',
    },
  ],
};
