import { Avatar, Badge, Box, Text, Tooltip } from '@chakra-ui/react';

import { RlDataProps } from '../../../Interfaces/pendingRequests';
import SubscriptionPanel from '../styles';

// TODO: el badge que ahora depende de estadoValidacionIdentidad, cuando se haga el servicio correcto va a depender de si firmo los TyC
function ItemRl({ data, statusBanks }: RlDataProps) {
  const confirmado = data.confirmationStatus === 'REQUEST_CONFIRMED';
  const pendiente = data.confirmationStatus === 'CONFIRMATION_PENDING';
  const renderList = () => (
    <SubscriptionPanel>
      <Box className='contentBoxRl' key={data.emailAddress}>
        {' '}
        <Box className='flexItem'>
          <Avatar
            className='avatar'
            id='avatar'
            backgroundColor='#2172DC'
            size='sm'
            color='white'
            name={`${data.name.split(' ')[0]} ${data.lastname.split(' ')[0]}`}
          />
          <Box m='10px' ml='20px'>
            <Text m='0px' textStyle='titleMdBold'>
              {`${data.name} ${data.lastname}`}
            </Text>
            <Text m='0px' textStyle='bodyMd'>
              {`Email: ${data.emailAddress}`}
            </Text>
          </Box>
        </Box>
        {pendiente && !statusBanks && (
          <Box position='relative' width='100%'>
            <Tooltip
              hasArrow
              placement='bottom'
              label='Para finalizar el alta de este banco deberás descargar los formularios que te brindaremos y presentarlos en una sucursal. Deben estar firmados por quienes representan a tu empresa'
            >
              <Badge variant='warning-light' position='absolute' right='38.5%' marginTop='-10px'>
                Por confirmar
              </Badge>
            </Tooltip>
          </Box>
        )}
        {confirmado && !statusBanks && (
          <Box position='relative' width='100%'>
            <Badge variant='success-light' position='absolute' right='39.5%' marginTop='-10px'>
              Confirmada
            </Badge>
          </Box>
        )}
        {/* SE COMENTA para proxima feature cuando este el servicio de core */}
        {/* {(pendiente || confirmado) && !statusBanks && (
          <Box>
            <Tooltip hasArrow placement='left' label='Volver a enviar la solicitud'>
              <button data-testid='send' id='send' className='send' disabled={confirmado} />
            </Tooltip>
          </Box>
        )} */}
      </Box>
    </SubscriptionPanel>
  );

  return <>{renderList()}</>;
}

export default ItemRl;
