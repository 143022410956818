/* eslint-disable react/jsx-props-no-spreading */
import { Accordion, Box, useToast } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { getOidcStoraged } from '@react-ib-mf/style-guide-ui';
import { ToastComponent, ToastComponentProps } from '@wow/toast';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { BANCOS_TIPO_SUSCRIPCION } from '../../constants';
import { GetBankContext } from '../../context/GetBankContext';
import StepFormContext from '../../context/StepFormContext';
import { useBanks } from '../../hooks/useBanks';
import { Bank } from '../../Interfaces/getBank';
import digestAccount from '../../utils/digestAccount';
import Breadcrumb from '../breadcrumb';
import FilterTag from '../filter';
import FlowButtons from '../flowButtons';
import GetBankAccordion from '../getBankAccordion';
import Header from '../header';
import Panel from '../header/styles';
import Loader from '../loading';
import ErrorEmptyBank from './ErrorEmptyBank';
import ErrorServiceBank from './ErrorServiceBank';
import { groupAccountsByBank } from './groupAccountsByField';

function GetBank() {
  const [listOfDigitalBankAccountsHash, setListOfCbuHashOfDigitalBanks] = useState<string[]>([]);
  const state = GetBankContext.useState();
  const dispatch = GetBankContext.useDispatch();
  const hasSelectedAccounts = Object.values(state.selectedAccounts).some(isSelected => isSelected);
  const cantSelectedAccounts = Object.values(state.selectedAccounts).filter(value => value).length;
  const [filterSelectedBank, setFilterSelectedBank] = useState<Set<Bank['denominacion']>>(new Set());
  const [cantSelectedBanks, setCantSelectedBanks] = useState<number>(0);
  const navigate = useNavigate();
  const dispatchStep = StepFormContext.useDispatch();
  const [isToastActive, setIsToastActive] = useState(false);

  let content;

  const {
    profile: { operador, idEntidad },
  } = getOidcStoraged();

  const {
    data: allBanks,
    isLoading,
    error,
    refetch: rlsRefetch,
  } = useBanks({ operador, idEntidad }, { select: data => (data ? groupAccountsByBank(data.cuentasSinAlta) : []) });
  useEffect(() => {
    if (state.owner !== idEntidad) {
      dispatch({ type: 'setOwner', payload: idEntidad });
    }
  }, [state.owner, idEntidad, dispatch]);

  const filteredBanks = useMemo(
    () => (allBanks ? allBanks?.filter(item => (filterSelectedBank.size === 0 ? true : filterSelectedBank.has(item.denominacion))) : []),
    [allBanks, filterSelectedBank],
  );

  const sortedBanks = useMemo(() => {
    return [...filteredBanks].sort((bankA, bankB) => bankA.denominacion.localeCompare(bankB.denominacion));
  }, [filteredBanks]);

  useEffect(() => {
    (async () => {
      const listOfCbuHashOfDigitalBanksPre = await Promise.all(
        allBanks
          ? allBanks
              ?.filter(({ tipoSuscripcion }) => tipoSuscripcion === BANCOS_TIPO_SUSCRIPCION.DIGITAL)
              .flatMap(({ accounts }) => accounts.map(account => digestAccount(account)))
          : [],
      );

      setListOfCbuHashOfDigitalBanks(listOfCbuHashOfDigitalBanksPre);
    })();
  }, [allBanks]);

  const hasSomeDigitalBankAccountSelected = useMemo(
    () => listOfDigitalBankAccountsHash.some(hash => state.selectedAccounts[hash]),
    [listOfDigitalBankAccountsHash, state],
  );

  const continueTo = !hasSomeDigitalBankAccountSelected
    ? '/digitales/resumen-suscripcion'
    : '/modulo-representante-legal/digitales/bancos/resumen-suscripcion';

  useEffect(() => {
    const countBanksSelected = async () => {
      const count = await Promise.all(
        allBanks.map(async bank => {
          const selectAccount = await Promise.all(bank.accounts.map(async account => state.selectedAccounts[await digestAccount(account)]));
          return selectAccount.some(Boolean);
        }),
      );
      const selectedCount = count.filter(Boolean).length;
      setCantSelectedBanks(selectedCount);
    };
    allBanks && countBanksSelected();
    setIsToastActive(false);
  }, [state.selectedAccounts, allBanks]);
  const toast = useToast();
  const toastOptions: ToastComponentProps = {
    id: 'toast-id',
    duration: 10000,
    position: 'bottom-left',
    status: 'info',
    description: 'Para agregar cuentas en dólares, seleccioná al menos una en pesos del mismo banco.',
    onClose: () => toast.close('toast-id'),
  };
  const openToast = () => {
    setIsToastActive(true);
    toast({
      ...toastOptions,
      render: () => <ToastComponent {...toastOptions} isCtaLabel={false} />,
    });
  };
  const handleToast = async () => {
    const invalidBanks = await Promise.all(
      sortedBanks.map(async bank => {
        const selectedAccounts = await Promise.all(
          bank.accounts.map(async account => {
            const hash = await digestAccount(account);
            return { hash, moneda: account.moneda, seleccionado: state.selectedAccounts[hash] };
          }),
        );
        const hasAccountPesos = selectedAccounts.some(({ seleccionado, moneda }) => seleccionado && moneda === '$');
        const hasSelectedAccountsSorted = selectedAccounts.some(({ seleccionado }) => seleccionado);

        return hasSelectedAccountsSorted && !hasAccountPesos ? bank.denominacion : null;
      }),
    );

    const bancosFiltrados = invalidBanks.filter(Boolean);

    if (bancosFiltrados.length > 0) {
      if (!toast.isActive('toast-id')) {
        openToast();
      }
    } else {
      dispatchStep({ type: 'nextStep' });
      navigate(continueTo);
    }
  };

  if (isLoading) return <Loader />;

  if (filteredBanks.length === 0 || error) {
    if (error) {
      content = <ErrorServiceBank rlsRefetch={() => rlsRefetch()} />;
    } else {
      content = <ErrorEmptyBank />;
    }
  } else {
    const bankArrayNumber = Array.from(allBanks.map((_, i) => i));

    content = (
      <>
        <Box style={{ width: '930px' }}>
          <FilterTag
            onChange={v => {
              setFilterSelectedBank(v);
            }}
            banks={sortedBanks}
          />

          <Accordion defaultIndex={[...bankArrayNumber]} allowMultiple>
            {sortedBanks.map(bank => (
              <GetBankAccordion key={bank.denominacion} bank={bank} />
            ))}
          </Accordion>
        </Box>
        <FlowButtons
          data={{
            volver: '/digitales',
            hasSomeDigitalBankAccountSelected,
            cantSelectedAccounts,
            cantSelectedBanks,
            isDisabled: !hasSelectedAccounts || isToastActive,
            handleModal: handleToast,
            text: 'Elegir',
          }}
        />
        <Box style={{ display: 'block', height: '160px' }} />
      </>
    );
  }

  return (
    <Panel>
      <Breadcrumb />
      <Header
        title='Elegí los bancos y cuentas'
        subtitle='Obtuvimos la informacón de las cuentas a través del CUIT de tu empresa.'
        showButton={false}
      />
      {content}

      <Outlet />
    </Panel>
  );
}

export default GetBank;
