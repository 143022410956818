export interface Accounts {
  denominacion: string;
  codBcoBcra: string;
  cbu: string;
  esCtaPropiaValida: boolean;
  tipo: string;
  moneda: string;
  denominacionBanco: string | null;
  bcoId: number;
}
export interface Account {
  accountCBU: string;
  accountType: string;
  accountCurrency: string;
}

export interface Bank {
  bankId: number;
  bankCodeBcra: string;
  account: Account[];
}

export function groupAccountsByBankToCreateRequest(collection: Accounts[]) {
  return [
    ...collection
      .reduce((acc, item) => {
        const { bcoId } = item;

        acc.set(bcoId, {
          bankId: bcoId,
          bankCodeBcra: item.codBcoBcra,
          account: (acc.get(bcoId)?.account || []).concat({
            accountCBU: item.cbu,
            accountType: item.tipo,
            accountCurrency: item.moneda,
          }),
        });

        return acc;
      }, new Map<number, Bank>())
      .values(),
  ];
}

export default groupAccountsByBankToCreateRequest;
