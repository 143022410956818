/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const ModalPanel = styled.div`
  .css-1ik4h6n {
    border: 0;
    color: #3f4759;
    background-color: transparent !important;
    cursor: pointer;
  }
  .css-onkibi {
    height: 16px !important;
    width: 16px !important;
  }
  .css-pv22qu {
    border: 1px solid #74777f !important;
    box-shadow: 0px 1px 3px 1px #00000026 !important;
    box-shadow: 0px 1px 2px 0px #0000004d !important;
    border-radius: 12px !important;
  }
  .css-1u2cvaz {
  }

  /* Estilos */

  .title-style {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
  }

  .text-style {
    font-family: 'Inter', sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.25px !important;
    text-align: center !important;
    padding-left: 23px !important;
    padding-right: 23px !important;
  }

  .image-style {
    margin: auto;
    padding-top: 60px;
  }
  .image-size {
    width: 76px;
    height: 76px;
  }
  .button-container {
    display: flex !important;
    justify-content: center !important;
  }
  .button-white-Style {
    color: #2172dc !important;
    background-color: white !important;
    border: 2px solid #2172dc !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .button-blue-Style {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
`;

export default ModalPanel;
