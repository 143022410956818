import { AccountWithMeta, Bank } from '../../Interfaces/getBank';

export function groupAccountsByBank(collection: AccountWithMeta[]) {
  return [
    ...collection
      .reduce((acc, item) => {
        const { denominacion } = item;

        acc.set(denominacion, {
          accounts: acc.has(denominacion) ? [...acc.get(denominacion).accounts, item] : [item],
          denominacion,
          codBcoBcra: item.codBcoBcra,
          tipoSuscripcion: item.meta.tipoSuscripcion,
        });

        return acc;
      }, new Map<AccountWithMeta['denominacion'], Bank>())
      .values(),
  ];
}

export default groupAccountsByBank;
