export const ADD_RLS = 'ADD_RLS';
export const ADD_ACCOUNTS = 'ADD_ACCOUNTS';
export const PREV_STEP = 'PREV_STEP';
export const NEXT_STEP = 'NEXT_STEP';
export const RESTART_STEP = 'RESTART_STEP';

export default (state, action) => {
  switch (action.type) {
    case ADD_RLS:
      return {
        ...state,
        rlsSubscription: action.payload,
      };
    default:
      return state;
  }
};
