import React from 'react';

import Message from '../message';

interface Refetched {
  rlsRefetch: (...args: any[]) => void;
}

function ErrorPendingRequest({ rlsRefetch }: Refetched) {
  return (
    <Message
      icon='disconected'
      title='La información no esta disponible'
      subtitle='Estamos trabajando para solucionarlo, intentá de nuevo en unos minutos.'
      button={{
        buttonLabel: 'Reintentar',
        buttonAction: () => {
          rlsRefetch();
        },
      }}
    />
  );
}

export default ErrorPendingRequest;
