import { Box, Button, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import StepFormContext from '../../context/StepFormContext';
import HeaderInterface from '../../Interfaces/header';
import Header from '../header';
import HomePanel from './styles';

function Home({ title, subtitle, showButton, subtitleButton }: HeaderInterface) {
  const dispatch = StepFormContext.useDispatch();
  return (
    <HomePanel>
      <Header title={title} subtitle={subtitle} showButton={showButton} subtitleButton={subtitleButton} />

      <Box id='homeBox' textAlign='center'>
        <Box id='image' className='nothingHereImg' />
        <Text mt='10px' mb='0px' id='title' textStyle='h4Bold'>
          Todavía no tenés solicitudes de alta
        </Text>
        <Text id='description' mt='2px' textStyle='bodyLg' mb='15px'>
          Agregá tus bancos para centralizar toda tu operatoria en Interbanking. Cuando lo hagas, podrás ver las solicitudes en curso acá.{' '}
        </Text>
        <Link to='/digitales/bancos'>
          <Button
            id='agregar'
            size='md'
            variant='primary'
            onClick={() => {
              dispatch({ type: 'nextStep' });
            }}
          >
            Agregar
          </Button>
        </Link>
      </Box>
    </HomePanel>
  );
}

export default Home;
