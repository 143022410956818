/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import image from '../../assets/img/zoom-in.svg';

const SubscriptionPanel = styled.div`
  .subsBoxTitle {
    border-bottom: solid 1px;
    background-color: rgb(214, 226, 255);
    border-color: #c7c6ca;
    height: 70px;
    border-radius: 8px 8px 0px 0px;
    padding: 8px 16px 8px 16px;
  }
  .boxItem {
    display: flex;
    background-color: #eff0fa;
    height: 52px;
    border-bottom: solid 1px;
    border-color: #c7c6ca;
    justify-content: space-between;
  }
  .contentBox {
    display: flex;
    background-color: white;
    height: 64px;
    border-bottom: solid 1px;
    border-color: #c7c6ca;
    justify-content: space-between;
    align-items: center;
  }
  .contentBoxRl {
    display: flex;
    background-color: white;
    height: 68px;
    border-bottom: solid 1px;
    border-color: #c7c6ca;
  }
  .flexItem {
    width: 588px;
    display: flex;
  }
  .detail {
    background-image: url(${image});
    height: 20px;
    width: 20px;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -40px;
    cursor: pointer;
  }
  .avatar {
    margin: 15px -10px 0 20px;
  }
  .buttonMargin {
    position: absolute;
    height: 50px;
    right: 55px;
  }
  .boxContainer {
    margin-right: 50px;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 150px;
  }
  .warning {
    margin-right: 50px;
    display: flex;
    border: solid 1px;
    background-color: #ffeedb;
    border-color: #ffddb4;
    height: 52px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .alertIcon {
    color: white;
    margin: 12px;
  }
  .boxIcon {
    background-color: #a76a00;
    display: flex;
    align-items: center;
    margin-right: 17px;
    width: 48px;
    border-radius: 8px 0 0 8px;
  }
  .boxError {
    text-align: center;
  }
`;

export default SubscriptionPanel;
