import { Box, Button, Text, useDisclosure } from '@chakra-ui/react';
import { useMemo } from 'react';
import { ChevronLeft } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

import GetBankContext from '../../context/GetBankContext';
import StepFormContext from '../../context/StepFormContext';
import Modals from '../modal';
import FlowButtonsInterface from './flowButtonsInterface';
import Panel from './styles';

function FlowButtons({ data }: FlowButtonsInterface) {
  const dispatchStep = StepFormContext.useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = GetBankContext.useDispatch();
  const navigate = useNavigate();
  const handleModal = () => {
    dispatchStep({ type: 'restartStep' });
    dispatch({ type: 'resetSelectedAccounts' });
    navigate('/digitales');
  };

  const openModal = () => {
    onOpen();
  };

  const stepText = useMemo(() => {
    if (data.volver === '/digitales') {
      return !data.hasSomeDigitalBankAccountSelected ? '1 de 2' : '1 de 3';
    }
    if (data.volver === '/digitales/bancos') {
      return '2 de 2';
    }
    return '3 de 3';
  }, [data]);
  const handleToast = () => {
    data.handleModal(null);
  };
  const selectedBanks = data.cantSelectedBanks > 1 ? 'bancos seleccionados' : 'banco seleccionado';
  const selectedAccounts = data.cantSelectedAccounts > 1 ? 'cuentas en total' : 'cuenta en total';

  return (
    <Panel>
      <Box className='boxButton' boxShadow={1}>
        <Box className='backButton'>
          <Link to={data.volver}>
            <Button
              onClick={() => {
                dispatchStep({ type: 'prevStep' });
              }}
              leftIcon={<ChevronLeft />}
              variant='primary-text'
            >
              Volver
            </Button>
          </Link>
        </Box>
        {!data?.notStep && (
          <Box className='boxSteps'>
            {data.cantSelectedBanks > 0 && (
              <>
                <Box className='countBank'>
                  [{data.cantSelectedBanks}] {selectedBanks}
                </Box>
                <Box className='countAccount'>
                  [{data.cantSelectedAccounts}] {selectedAccounts}
                </Box>
              </>
            )}
            <Text w='90px' textStyle='labelLg'>{`Paso ${stepText}`}</Text>

            <Box className='buttonDuo'>
              <Button onClick={openModal} size='md' variant='primary-outline' isDisabled={data.isDisabled}>
                Cancelar
              </Button>
              {data.text !== 'Confirmar' ? (
                <Link to={data.confirmar}>
                  <Button onClick={handleToast} isDisabled={data.isDisabled} size='md' variant='primary'>
                    {data.text}
                  </Button>
                </Link>
              ) : (
                <Button onClick={data.handleModal} isDisabled={data.isDisabled} size='md' variant='primary'>
                  {data.text}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Modals
        title={
          <>
            ¿Querés descartar el <br />
            progreso?
          </>
        }
        description={
          <>
            Al salir de esta sección se borrará el <br />
            progreso que hiciste.
          </>
        }
        buttonName1='Cancelar'
        buttonAction2={handleModal}
        buttonAction1={onClose}
        buttonName2='Sí, descartar'
        type='alert'
        isOpen={isOpen}
        onClose={onClose}
      />
    </Panel>
  );
}

export default FlowButtons;
