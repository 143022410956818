/* eslint-disable import/no-unresolved */
import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { getOidcStoraged } from '@react-ib-mf/style-guide-ui';
import { useEffect, useMemo, useState } from 'react';
import { AlertTriangle } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { BANCOS_TIPO_SUSCRIPCION } from '../../constants';
import GetBankContext from '../../context/GetBankContext';
import StepFormContext from '../../context/StepFormContext';
import useApplications from '../../hooks/useApplications';
import { useBanks } from '../../hooks/useBanks';
import { useRepresentanteLegal } from '../../hooks/useRepresentanteLegal';
import { CreateApplication } from '../../Interfaces/createApplication';
import { LegalRepresentative } from '../../Interfaces/DataSubscriptionInterface';
import { AccountWithMeta } from '../../Interfaces/getBank';
import Breadcrumb from '../breadcrumb';
import FlowButtons from '../flowButtons';
import Header from '../header';
import LoaderWithOpacity from '../loader-with-opacity';
import Loader from '../loading';
import Modals from '../modal';
import { filterAccountsBySelected } from './filterAccountsBySelected';
import groupAccountsByBankToCreateRequest from './groupAccountsByBankToCreateRequest';
import { groupAccountsByTipoSuscripcion } from './groupAccountsByTipoSuscripcion';
import groupLegalRepresentativesById from './groupLegalRepresentativesById';
import ItemAccount from './itemAccount';
import ItemRl from './itemRl';
import SubscriptionPanel from './styles';
import useSendApplication from './useSendApplication';

interface RlsData {
  legalRepresentatives: LegalRepresentatives[];
}
interface LegalRepresentatives {
  id: number;
}
function SubscriptionSummary() {
  const dispatch = StepFormContext.useDispatch();
  const dispatchAccounts = GetBankContext.useDispatch();

  const state = GetBankContext.useState();
  const [filteredBanksData, setFilteredBanksData] = useState<AccountWithMeta[]>([]);
  const [filteredRls, setFilteredRls] = useState<RlsData>({ legalRepresentatives: [] });
  const { modalSendApplicationSuccess, modalSendApplicationfailed, sendApplication, isSendingApplication } = useSendApplication();

  const {
    profile: { operador, idEntidad },
  } = getOidcStoraged();
  const navigate = useNavigate();

  const hasDigitalSubscription = useMemo(
    () => filteredBanksData.some(item => item.meta.tipoSuscripcion === BANCOS_TIPO_SUSCRIPCION.DIGITAL),
    [filteredBanksData],
  );

  const {
    data: rlsData,
    error: rlsError,
    isLoading: rlsIsLoading,
    refetch: rlsRefetch,
  } = useRepresentanteLegal({ shouldFetch: hasDigitalSubscription });
  const { data: banksData, isLoading: banksIsLoading } = useBanks({ operador, idEntidad });
  useEffect(() => {
    if (
      state?.selectedAccounts !== undefined &&
      (Object.keys(state?.selectedAccounts).length === 0 || Object.values(state?.selectedAccounts).every(value => value === false))
    ) {
      dispatch({ type: 'restartStep' });
    }
    (async () => {
      setFilteredBanksData(
        await filterAccountsBySelected({
          accounts: banksData?.cuentasSinAlta ?? [],
          selectedAccounts: state?.selectedAccounts,
        }),
      );
    })();
  }, [banksData, state?.selectedAccounts, dispatch]);
  useEffect(() => {
    if (hasDigitalSubscription && rlsData?.legalRepresentatives) {
      (async () => {
        setFilteredRls(await groupLegalRepresentativesById(rlsData));
      })();
    }
  }, [hasDigitalSubscription, rlsData]);

  useEffect(() => {
    if (hasDigitalSubscription) {
      rlsRefetch();
    }
  }, [hasDigitalSubscription, rlsRefetch]);

  const hasPhysicSubscription = useMemo(
    () => filteredBanksData.some(item => item.meta.tipoSuscripcion === BANCOS_TIPO_SUSCRIPCION.FISICA),
    [filteredBanksData],
  );

  const groupedBanksData = useMemo(() => groupAccountsByTipoSuscripcion(filteredBanksData), [filteredBanksData]);

  const isLoading = rlsIsLoading && banksIsLoading;
  const backTo = hasDigitalSubscription ? '/modulo-representante-legal/digitales/bancos/resumen-suscripcion' : '/digitales/bancos';

  const applicationInProgress = hasDigitalSubscription
    ? { legalRepresentatives: filteredRls.legalRepresentatives, banks: groupAccountsByBankToCreateRequest(filteredBanksData) }
    : { banks: groupAccountsByBankToCreateRequest(filteredBanksData) };
  const { refetch: appRefetch } = useApplications();

  const handleSuccess = () => {
    modalSendApplicationSuccess.onClose();
    dispatch({ type: 'restartStep' });
    dispatchAccounts({ type: 'resetSelectedAccounts' });
    appRefetch();
    navigate('/digitales');
  };
  const handleModal = () => {
    sendApplication.mutate(applicationInProgress as CreateApplication);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <SubscriptionPanel>
      {isSendingApplication ? <LoaderWithOpacity /> : null}
      <Breadcrumb />
      <Header
        title='Confirmá tu solicitud de alta'
        subtitle={
          hasDigitalSubscription
            ? 'Notificaremos a tus representantes legales / personas apoderadas para confirmar la solicitud. Luego deberá ser aprobada por los bancos.'
            : 'Tené en cuenta que todos los bancos que seleccionaste son de suscripción física. En este caso, no es obligatorio cargar representantes.'
        }
        showButton={false}
      />

      {hasPhysicSubscription ? (
        <Box mt='20px' mb='0px' className='warning'>
          <Box className='boxIcon'>
            <Icon className='alertIcon' boxSize={6} as={AlertTriangle} />
          </Box>
          <Box>
            <Text m='0px' mt='6px' textStyle='titleSmBold'>
              Tenés bancos con suscripción física
            </Text>
            <Text m='0px' textStyle='bodySm'>
              Descargá los formularios para estos casos. Tus representantes legales / personas apoderadas deberán firmar la carta de
              ratificación y presentarla.
            </Text>
          </Box>
        </Box>
      ) : null}

      <Box mt='20px' className='boxContainer' boxShadow={1}>
        <Box className='subsBoxTitle'>
          <Text m='0px' mt='12px' textStyle='h5Bold'>
            Solicitud
          </Text>
        </Box>

        {groupedBanksData.map(elem => (
          <ItemAccount key={elem.tipoSuscripcion} data={elem} />
        ))}
        {hasDigitalSubscription ? (
          <>
            <Box className='boxItem'>
              <Text m='0px' p='12px' w='1053px' textStyle='titleMdBold'>
                Representantes legales / personas apoderadas {rlsData ? `(${rlsData?.legalRepresentatives?.length})` : ''}
              </Text>
            </Box>

            {rlsError ? (
              <Box className='boxError'>
                <Text mt='10px' id='title' textStyle='h4Bold'>
                  La información no esta disponible
                </Text>
                <Text id='description' textStyle='bodyLg'>
                  Estamos trabajando para solucionarlo.
                </Text>
                <Button
                  onClick={() => {
                    rlsRefetch();
                  }}
                  variant='primary-text'
                >
                  Reintentar
                </Button>
              </Box>
            ) : (
              rlsData?.legalRepresentatives?.map((item: LegalRepresentative) => <ItemRl key={item.emailDirection} data={item} />)
            )}
          </>
        ) : null}
      </Box>
      {modalSendApplicationSuccess && modalSendApplicationSuccess.isOpen ? (
        <Modals
          title={<>Creaste la solicitud</>}
          description={
            <>
              Para las <strong>suscripciones digitales</strong>, enviaremos un <br />
              email a tus representantes. Cuando confirmen,
              <br /> deberán aprobar los bancos.
              <br /> <br />
              Para las <strong>suscripciones físicas</strong>, descargá los
              <br /> formularios. Tus representantes deben firmar la <br />
              carta de ratificación y presentarla en los bancos.
            </>
          }
          buttonName1='Entendido'
          buttonAction1={handleSuccess}
          type='newConfirm'
          isOpen={modalSendApplicationSuccess.isOpen}
          onClose={handleSuccess}
        />
      ) : null}
      {modalSendApplicationfailed && modalSendApplicationfailed.isOpen ? (
        <Modals
          title={
            <>
              No podemos procesar tu <br />
              solicitud
            </>
          }
          description={
            <>
              Estamos trabajando para solucionarlo.
              <br />
              Volvé a intentar más tarde.
            </>
          }
          buttonName1='Entendido'
          buttonAction1={modalSendApplicationfailed.onClose}
          type='cancel'
          isOpen={modalSendApplicationfailed.isOpen}
          onClose={modalSendApplicationfailed.onClose}
        />
      ) : null}
      <FlowButtons
        data={{
          isDisabled: rlsData === null || !!rlsError,
          volver: backTo,
          confirmar: '/digitales',
          text: 'Confirmar',
          hasSomeDigitalBankAccountSelected: hasDigitalSubscription,
          handleModal,
        }}
      />
    </SubscriptionPanel>
  );
}

export default SubscriptionSummary;
