import { Dispatch, useEffect, useReducer } from 'react';

export interface UsePersistedReducerOptions {
  /**
   * Storage to use
   *
   * Default: localStorage
   */
  storage?: Storage;
}

export function usePersistedReducer<TState, TAction>(
  reducer: (state: TState, action: TAction) => TState,
  reducerInitState: TState,
  persistedKey: string,
  options: UsePersistedReducerOptions = {},
): [TState, Dispatch<TAction>] {
  const { storage = localStorage } = options;

  function init(initState: TState) {
    try {
      const persistedState = storage.getItem(persistedKey);

      if (!persistedState) return initState;

      return JSON.parse(persistedState);
    } catch {
      return initState;
    }
  }

  const [state, dispatch] = useReducer(reducer, reducerInitState, init);

  useEffect(() => {
    storage.setItem(persistedKey, JSON.stringify(state));
  }, [persistedKey, state, storage]);

  return [state, dispatch];
}

export default usePersistedReducer;
