import createdPersistedReducerContext from '../utils/createPersistedReducerContext';

interface State {
  currentStep: number;
}
type Action<TType, TPayload> = { type: TType; payload?: TPayload };
type Actions =
  | Action<'prevStep', undefined>
  | Action<'nextStep', undefined>
  | Action<'restartStep', undefined>
  | Action<'setStepSummary', undefined>;

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case 'prevStep':
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };

    case 'nextStep':
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };

    case 'restartStep':
      return {
        ...state,
        currentStep: 0,
      };
    case 'setStepSummary':
      return {
        ...state,
        currentStep: 2,
      };

    default:
      return state;
  }
}

const initState: State = {
  currentStep: 0,
};

const persistedKey = `${new URL(window.location.href).hostname.split('.').reverse().join('.')}:step-form-context`;

export const StepFormContext = createdPersistedReducerContext(reducer, initState, persistedKey, { storage: sessionStorage });
export default StepFormContext;
