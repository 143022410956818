import { useQuery } from 'react-query';

import { getApplications } from '../services/api';

type FetchedData = Awaited<ReturnType<typeof getApplications>>['data']['data'];

export function useApplications<TData = FetchedData>({ select }: { select?: (d: FetchedData) => TData } = {}) {
  return useQuery({
    queryKey: ['getApplications'],
    queryFn: () => getApplications().then(res => res.data.solicitudes),
    select,
  });
}

export default useApplications;
