import { createContext, Dispatch, ReactNode, useContext } from 'react';

import { usePersistedReducer, UsePersistedReducerOptions } from '../hooks/usePersistedReducer';

type createdPersistedReducerContextOptions = UsePersistedReducerOptions;

export function createdPersistedReducerContext<TState, TActions>(
  reducer: (state: TState, action: TActions) => TState,
  initState: TState,
  persistedKey: string,
  options: createdPersistedReducerContextOptions = {},
) {
  const StateContext = createContext<TState>(null as unknown as TState);
  const DispatchContext = createContext<Dispatch<TActions>>(null as unknown as Dispatch<TActions>);

  return {
    Provider({ children }: { children: ReactNode }) {
      const [state, dispatch] = usePersistedReducer(reducer, initState, persistedKey, options);

      return (
        <StateContext.Provider value={state}>
          <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
      );
    },

    useState() {
      return useContext(StateContext);
    },

    useDispatch() {
      return useContext(DispatchContext);
    },
  };
}

export default createdPersistedReducerContext;
