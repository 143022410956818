// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';

import { CreateApplication } from '../Interfaces/createApplication';
import { LegalRepresentative } from '../Interfaces/DataSubscriptionInterface';
import { AccountWithMeta } from '../Interfaces/getBank';
import generateUUID from '../utils/generateUUID';

axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`;

const defaultTransformRequests = axios.defaults.transformRequest;
const newAxios = axios.create({
  baseURL: process.env.URL_BANCOS_DIGITALES,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: process.env.API_USER_KEY },
  transformRequest: [
    (data, headers) => {
      // eslint-disable-next-line no-param-reassign
      headers['x-interbanking-tracking-code'] = generateUUID();
      return data;
    },
    // eslint-disable-next-line no-nested-ternary
    ...(defaultTransformRequests ? (Array.isArray(defaultTransformRequests) ? defaultTransformRequests : [defaultTransformRequests]) : []),
  ],
});

export const getRepresentanteLegalValidado = (operador: number) => newAxios.get(`/representante-legal-validado/${operador}`);
export const putEditEmail = (idPersona: number, email: string) => newAxios.put(`/editar-mail/${idPersona}`, { email });
export const getBank = (operador: number, idEntidad) =>
  newAxios.get<{ cuentasSinAlta: AccountWithMeta[] }>(`obtener-cuentas?operadorId=${operador}&entidadId=${idEntidad}`);
export const sendMail = (operador: number, personaId: number, lastName: string, name: string) =>
  newAxios.post(`/reenviar-email/${operador}`, { personaId, lastName, name });
export const getApplications = () => newAxios.get(`/v1/suscripciones/solicitudes`);

export const getRepresentanteLegal = () =>
  newAxios.get<{ data: { legalRepresentatives: LegalRepresentative[] } }>(`/v1/legal-representative`);

export const postApplicaton = (body: CreateApplication) => newAxios.post(`/v1/suscripciones/solicitudes`, body);
export const getForms = (requestId: string) =>
  newAxios.get(`/v1/documents/${requestId}`, {
    responseType: 'blob',
  });
