import { Avatar, Box, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';

import { Bank } from '../../../Interfaces/getBank';
import DrawerAccounts from '../../drawerAccounts/DrawerAccounts';
import { GroupByTipoSuscripcionReturn } from '../groupAccountsByTipoSuscripcion';
import SubscriptionPanel from '../styles';

function ItemAccount({ data }: { data: GroupByTipoSuscripcionReturn[number] }) {
  const btnRef = React.useRef();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedBank, setSelectedBank] = useState({
    accounts: [{ tipo: '', moneda: '', cbu: '' }],
    denominacion: '',
    codBcoBcra: '',
  });

  const openDrawerForDetail = (bank: Bank) => {
    onOpen();
    setSelectedBank({
      accounts: bank.accounts.map(item => ({ tipo: item.tipo, moneda: item.moneda, cbu: item.cbu })),
      denominacion: bank.denominacion,
      codBcoBcra: bank.codBcoBcra,
    });
  };

  return (
    <SubscriptionPanel>
      <Box className='boxItem'>
        <Text m='0px' p='12px' w='1053px' textStyle='titleMdBold'>
          {' '}
          {data.tipoSuscripcion === 'física'
            ? `Bancos con suscripción física (${data.data.length})`
            : `Bancos digitales (${data.data.length})`}
        </Text>
        {/* se comenta por posible uso mas adelante */}
        {/* {data.tipoSuscripcion === 'física' ? (
          <Button
            className='buttonMargin'
            leftIcon={<Download />}
            loadingText='Descargar Formularios'
            size='sm'
            spinnerPlacement='start'
            variant='primary-text'
          >
            Descargar Formularios
          </Button>
        ) : null} */}
      </Box>

      {data.data.map((item: Bank) => (
        <Box className='contentBox' key={item.codBcoBcra}>
          {' '}
          <Box className='flexItem'>
            <Avatar
              className='avatar'
              id='avatar'
              backgroundColor='#2172DC'
              size='sm'
              color='white'
              name={`${item.denominacion}`}
              src={`${process.env.URL_BANK_LOGOS}logo${item.codBcoBcra}.png`}
            />
            <Text m='0px' p='20px' textStyle='h6'>
              {item.denominacion}
            </Text>
          </Box>
          <Box className='flexItem'>
            <Text m='0px' p='20px' textStyle='bodyMd'>
              {`${item.accounts.length} cuentas`}
            </Text>
          </Box>
          <Box>
            <Tooltip hasArrow placement='left' label='Ver más detalle'>
              <button onClick={() => openDrawerForDetail(item)} data-testid='detail' className='detail' />
            </Tooltip>
          </Box>
        </Box>
      ))}
      <DrawerAccounts isOpen={isOpen} onClose={onClose} btnRef={btnRef} data={selectedBank} />
    </SubscriptionPanel>
  );
}

export default ItemAccount;
