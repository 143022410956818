/* eslint-disable react/no-array-index-key */
import { Avatar, Box, Text } from '@chakra-ui/react';

import { LegalRepresentative } from '../../../Interfaces/DataSubscriptionInterface';
import SubscriptionPanel from '../styles';

function ItemRl({ data }: { data: LegalRepresentative }) {
  return (
    <SubscriptionPanel>
      <Box className='contentBoxRl' key={data.emailDirection}>
        {' '}
        <Box className='flexItem'>
          <Avatar className='avatar' id='avatar' backgroundColor='#2172DC' size='sm' color='white' name={`${data.name} ${data.lastname}`} />
          <Box m='10px' ml='20px'>
            {' '}
            <Text m='0px' textStyle='titleMdBold'>
              {`${data.name[0].toUpperCase() + data.name.slice(1)} ${data.lastname[0].toUpperCase() + data.lastname.slice(1)}`}
            </Text>
            <Text m='0px' textStyle='bodyMd'>
              {`Email: ${data.emailDirection}`}
            </Text>
          </Box>
        </Box>
      </Box>
    </SubscriptionPanel>
  );
}

export default ItemRl;
