import { Box, Button, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@wow/utils';
import { Link } from 'react-router-dom';

import StepFormContext from '../../context/StepFormContext';
import HeaderInterface from '../../Interfaces/header';
import Plus from '../btns/plus';
import Panel from './styles';

function Header({ title, subtitle, subtitleButton, showButton = true }: HeaderInterface) {
  const dispatch = StepFormContext.useDispatch();
  const url = `${process.env.SIB_BANCOS_SERVICIOS_VIGENTES}`;

  return (
    <Panel>
      <Box className='box-header'>
        <Text mb='15px' id='title-digital' as='h2' textStyle='h2Bold'>
          {title}
        </Text>

        <Box id='agregar-bancos' p='5px' pl='50px' mr='-48px'>
          {showButton ? (
            <Link to='/digitales/bancos'>
              <Button
                onClick={() => {
                  dispatch({ type: 'nextStep' });
                }}
                leftIcon={<Plus />}
                size='md'
                variant='primary'
              >
                Agregar bancos
              </Button>
            </Link>
          ) : null}
        </Box>
      </Box>
      <Text mt='-11px' textStyle='bodyMd'>
        {subtitle}
        {subtitleButton && (
          <OpenLink isRouter={false} url={url} openMode='newTab' reuseTab>
            <Button id='plan' className='planContratado' pl='0px' size='md' variant='primary-text'>
              {subtitleButton}
            </Button>
          </OpenLink>
        )}
      </Text>
    </Panel>
  );
}

export default Header;
