/* eslint-disable import/prefer-default-export */
export const BANCOS_TIPO_SUSCRIPCION = {
  FISICA: 'física',
  DIGITAL: 'digital',
};

export const CONST_BREADCRUMB = [
  {
    key: 'inicio',
    id: 'Inicio',
    label: 'Inicio',
    link: '/',
  },
  {
    key: 'administracion',
    id: 'Administracion',
    label: 'Administración',
    link: '',
  },
  {
    key: 'abm',
    id: 'abm',
    label: 'ABM configuración de datos',
    link: '/menu/Administración/abm',
  },
];

export const STATUS_BANK_PROCESS = ['PROCESS_STARTED', 'STORED'];
export const STATUS_BANK_ERROR = ['RULE_FAILED', 'RETRY_FAIL'];
export const STATUS_REQUEST = ['SENT_TO_BANK'];
