import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';

import alert from '../../assets/img/alert.svg';
import cancel from '../../assets/img/cancel.svg';
import checkmark from '../../assets/img/checkmark.svg';
import newAlert from '../../assets/img/newAlert.svg';
import newConfirm from '../../assets/img/newCheckmark.svg';
import ModalInterface from '../../Interfaces/modalInterface';
import ModalPanel from './styles';

function Modals({ title, description, buttonName1, buttonName2, type, isOpen, onClose, buttonAction2, buttonAction1 }: ModalInterface) {
  const getImage = () => {
    switch (type) {
      case 'confirm':
        return checkmark;
      case 'alert':
        return alert;
      case 'cancel':
        return cancel;
      case 'newAlert':
        return newAlert;
      case 'newConfirm':
        return newConfirm;
      default:
        return checkmark;
    }
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered onClose={onClose}>
      <ModalPanel>
        <ModalOverlay bg='rgba(0, 0, 0, 0.75)' />
        <ModalContent maxW='400px' maxH='468px'>
          <div className='image-style'>
            {/* Muestra la imagen correspondiente al type */}
            <img className='image-size' src={getImage()} alt='Icon' />
          </div>
          <ModalHeader className='title-style'>{title}</ModalHeader>
          <ModalBody className='text-style'>{description}</ModalBody>
          <ModalFooter className='button-container'>
            {buttonName2 ? (
              <>
                <Button
                  mb='20px'
                  size='md'
                  className='button-white-Style'
                  colorScheme='blue'
                  mr={3}
                  onClick={() => {
                    onClose();
                    buttonAction1();
                  }}
                  _hover={{ background: '#2172DC' }}
                >
                  {buttonName1}
                </Button>

                <Button
                  mb='20px'
                  size='md'
                  className='button-blue-Style'
                  colorScheme='blue'
                  mr={3}
                  onClick={() => {
                    onClose();
                    buttonAction2();
                  }}
                  _hover={{ background: '#2172DC' }}
                >
                  {buttonName2}
                </Button>
              </>
            ) : (
              <Button
                mb='20px'
                size='md'
                className='button-blue-Style'
                colorScheme='blue'
                mr={3}
                onClick={() => {
                  // istanbul ignore next
                  onClose(); // istanbul ignore next
                  buttonAction1();
                }}
                _hover={{ background: '#2172DC' }}
              >
                {buttonName1}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalPanel>
    </Modal>
  );
}

export default Modals;

/*
Descripcion del Modal

Tipos de props y datos que recibe:

title: Nombre del titulo requerido / el tipo de dato es JSX
description: Nombre del titulo requerido / el tipo de dato es JSX
buttonName1: Nombre boton requerido / tipo de dato string
buttonName2: Nombre boton requerido / tipo de dato string / es opcional
buttonAction1 = () => {} / tipo de dato function / es opcional / estado inicial funcion vacia 
buttonAction2 = () => {} / tipo de dato function / es opcional / estado inicial funcion vacia
type: Acepta 3 tipos  'confirm' / 'alert' / 'cancel' depende de cual seleccione mostrara una imagen u otra.
isOpen: tipo de dato booleano
onClose: tipo de dato function

Ejemplo de su uso:

import { useDisclosure } from '@chakra-ui/react'; // se importa useDisclosure para la animacion del modal
import Modals from '../modal';

const { isOpen, onOpen, onClose } = useDisclosure(); // puede ser que el componente que utilices ya este utilizando useDisclosure en otra parte,
en ese caso se puede crear una customizada para que no se pisen ambas a la ves.
Ejemplo
const ModalFailed = useDisclosure();

const handleButton1Click = () => {
  onOpen(); // si no se repite en el componente el useDisclosure
  ModalFailed.onOpen(); // custom en caso de que se repita en el componente el useDisclosure
};

const handleButton2Click = () => {
    // Ejemplo: Realiza la función adicional al hacer clic en button2
    console.log('Realizando otra función al hacer clic en button2');
    // Se agrega cualquier otra lógica que quieras ejecutar
  
  };

  
<>
 <Button onClick={() => handleButton1Click()}>handleButton1Click</Button>
        {ModalQueryFailed.isOpen ? (
          <Modals
            title={
              <>
                No podemos <br />
                editar el email
              </>
            }
            description={
              <>
                Estamos trabajando para solucionarlo. <br />
                Volvé a intentar más tarde.
              </>
            }
            buttonName1='Cancelar'
            buttonAction2={handleButton2Click}
            buttonName2='Sí, descartar'
            type='cancel'
            isOpen={ModalFailed.isOpen}
            onClose={ModalFailed.onClose}
            
          />
        ) : null}
</>

Otro ejemplo:

const ModalSuccess = useDisclosure();

const handleButton1Click = () => {
  onOpen(); // si no se repite en el componente el useDisclosure

  ModalSuccess.onOpen(); // custom en caso de que se repita en el componente el useDisclosure
};
 
<>
      <Button onClick={() => handleButton1Click()}>handleButton1Click</Button>
        {ModalQuerySuccess.isOpen ? (
          <Modals
            title={
              <>
                Listo!
              </>
            }
            description={
              <>
               Se realizo con exito tu solicitud.
              </>
            }
            buttonName1='Finalizar'
            type='confirm'
            isOpen={ModalSuccess.isOpen}
            onClose={ModalSuccess.onClose}
          />
        ) : null}
  </>

*/
