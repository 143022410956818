/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import tooltipImage from '../../assets/img/alert.svg';

const Panel = styled.div`
  .css-1uoq1w0 {
    border-radius: 8px 8px 0px 0px;
  }
  .tbody-disabled {
    color: #c7c6ca;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: #2f3034;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 7.5px;
    position: absolute;
    z-index: 1;
    top: -10px;
    left: 120%;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
  }

  .tooltip .tooltip-text::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #2f3034 transparent transparent;
  }
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }

  .accordion-disabled {
    opacity: 1;
    color: #c7c6ca;
    background-color: #f2f0f4;
    border-radius: 8px 8px 0px 0px;
  }

  .accordion-disabled .css-32jtbf[aria-expanded='true']:hover,
  .css-32jtbf[data-expanded]:hover,
  .css-32jtbf[aria-expanded='true'][data-hover],
  .css-32jtbf[data-expanded][data-hover] {
    background-color: #f2f0f4;
  }

  .accordion-disabled .css-32jtbf[aria-expanded='true'],
  .css-32jtbf[data-expanded] {
    background-color: #f2f0f4;
  }

  .accordion-disabled .gxIUYA .css-32jtbf[aria-expanded='true'],
  .gxIUYA .css-32jtbf[data-expanded] {
    background-color: #f2f0f4;
  }

  .accordion-disabled .css-32jtbf[aria-expanded='false']:focus,
  .css-32jtbf[data-expanded]:focus,
  .css-32jtbf[aria-expanded='false'][data-focus],
  .css-32jtbf[data-expanded][data-focus] {
    background-color: #f2f0f4 !important;
  }

  .accordion-disabled .css-32jtbf[aria-expanded='true']:focus,
  .css-32jtbf[data-expanded]:focus,
  .css-32jtbf[aria-expanded='true'][data-focus],
  .css-32jtbf[data-expanded][data-focus] {
    background-color: #f2f0f4 !important;
  }
  .box-avatar-checkbox {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .accordion-collapsed {
    border: 1px solid #bec6dc !important;
  }
  .accordion-selected {
    border: 3px solid var(--Color-Primary-100, #2172dc);
  }

  .accordion-selected-expanded[aria-expanded='true'],
  .accordion-selected-expanded[data-expanded] {
    border: 3px solid var(--Color-Primary-100, #2172dc) !important;
    border-bottom: 0px !important;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    width: 97%;
    padding-left: 0.25rem;
  }

  .tooltip-suscription {
    display: flex;
    align-items: center;
  }

  .tooltip-header {
    width: 294px !important;
    height: 47px;
  }

  .MaxWidpercent {
    width: 2%;
  }

  .my-tab-DNDA {
    width: 930px;
    border: 1px solid #bec6dc;
  }

  .table-selected {
    border: 3px solid var(--Color-Primary-100, #2172dc) !important;
    border-top: 0 !important;
  }
  .chakra-accordion__item {
    margin-bottom: -10px;
  }

  .checkbox-position {
    position: relative;
    top: 45px;
    right: -23px;
    width: 50px;
  }
  .acco {
    background-color: #eff0fa !important;
  }
  .header-tab {
    text-transform: initial !important;
    font-family: 'Inter', sans-serif;
    font-size: 14px !important;
  }
  .trick {
    margin-bottom: -8px;
    height: 20px;
    width: 100%;
    display: block;
  }
  .chakra-collapse {
    padding: 0px !important;
  }

  .css-32jtbf[aria-expanded='true'],
  .css-32jtbf[data-expanded] {
    background-color: #ffffff;
    border: 1px solid #bec6dc;
    width: 930px important!;
  }
  .position-meta {
    width: 125px;
    justify-content: flex-end;
    display: flex;
    font-size: 10px;
    right: 91px;
    border-radius: 12px;
    color: #855300;
    background: #ffeedb;
    align-content: center;
    align-items: center;
    background-image: url(${tooltipImage});
    background-position: 9px 5px;
    flex-wrap: nowrap;
    background-repeat: no-repeat;
    padding-right: 8px;
    background-size: 14px;
  }
  .blue {
    color: #2172dc;
  }
`;

export default Panel;
