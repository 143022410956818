import styled from 'styled-components';

const Panel = styled.div`
  .boxButton {
    display: flex;
    justify-content: space-between;
    position: fixed;
    height: 72px;
    bottom: 0px;
    background-color: white;
    margin-left: -34px;
    width: 97%;
    align-items: center;
  }
  .countBank {
    position: absolute;
    left: 150px;
    font-weight: bold;
    font-size: 14px;
  }
  .countAccount {
    position: absolute;
    left: 370px;
    font-weight: 400;
    font-size: 14px;
  }
  .buttonDuo {
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    margin-right: 56px;
  }
  .backButton {
    width: 1000px;
  }
  .boxSteps {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

export default Panel;
