import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { CreateApplication } from '../../Interfaces/createApplication';
import { postApplicaton } from '../../services/api';

const useSendApplication = () => {
  const [isSendingApplication, setIsSendingApplication] = useState(false);
  const modalSendApplicationSuccess = useDisclosure();
  const modalSendApplicationfailed = useDisclosure();
  const sendApplication = useMutation(
    async (body: CreateApplication) => {
      setIsSendingApplication(true);
      await postApplicaton(body);
    },
    {
      onSuccess: () => {
        modalSendApplicationSuccess.onOpen();
      },
      onError: () => {
        modalSendApplicationfailed.onOpen();
      },
      onSettled: () => {
        setIsSendingApplication(false);
      },
    },
  );

  return { modalSendApplicationSuccess, modalSendApplicationfailed, sendApplication, isSendingApplication };
};

export default useSendApplication;
