import { Box, Text } from '@chakra-ui/react';
import { CircularProgressComponent } from '@wow/progress-indicator';

import Panel from './styled';

function LoaderWithOpacity() {
  return (
    <Panel>
      <Box className='container'>
        <CircularProgressComponent isIndeterminate value={75} />
        <Box className='text-container'>
          <Text as='h1' textStyle='h4Bold' color='white'>
            Estamos procesando tu solicitud
          </Text>
          <Text textStyle='bodyLg' color='white'>
            Esto puede demorar unos instantes.
          </Text>
        </Box>
      </Box>
    </Panel>
  );
}

export default LoaderWithOpacity;
