import { Accordion, Box, Icon, Text } from '@chakra-ui/react';
import { AlertTriangle } from 'react-feather';

import { STATUS_BANK_ERROR } from '../../constants';
import { Application, Bank } from '../../Interfaces/pendingRequests';
import SubscriptionListInterface from '../../Interfaces/subscription-list';
import Header from '../header';
import ApplicationList from './applicationList';
import Panel from './styles';

function SubscriptionList({ titleHome, subtitleHome, showButton, subtitleButton, applications }: SubscriptionListInterface) {
  const warning = applications?.some((acc: Application) =>
    acc.banks.some((item: Bank) => item.digitalSubscription === false && !STATUS_BANK_ERROR.includes(item.bankStatus)),
  );
  return (
    <Panel>
      <Header title={titleHome} subtitle={subtitleHome} showButton={showButton} subtitleButton={subtitleButton} />
      {warning ? (
        <Box className='warning'>
          <Box className='boxIcon'>
            <Icon className='alertIcon' boxSize={6} as={AlertTriangle} />
          </Box>
          <Box>
            <Text m='0px' mt='6px' textStyle='titleSmBold'>
              Tenés bancos con suscripción física
            </Text>
            <Text className='warningDesc' textStyle='bodySm'>
              Descargá los formularios para estos casos. Tus representantes legales / personas apoderadas deberán firmar la carta de
              ratificación y presentarla.
            </Text>
          </Box>
        </Box>
      ) : null}
      <Box mr='50' id='applications-accordion'>
        <Box className='boxContainer'>
          <Accordion defaultIndex={[0]} allowMultiple bg='white'>
            {applications?.map((app: Application) => (
              <ApplicationList key={app.requestId} data={app} />
            ))}
          </Accordion>
        </Box>
      </Box>
      <Box mb='100px' />
    </Panel>
  );
}

export default SubscriptionList;
