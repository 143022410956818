interface RlsData {
  legalRepresentatives: LegalRepresentatives[];
}
interface LegalRepresentatives {
  id: number;
}

function groupLegalRepresentativesById(data): RlsData {
  const { legalRepresentatives } = data;

  const groupedLegalRepresentatives = legalRepresentatives.reduce(
    (acc: { [id: number]: LegalRepresentatives }, representative: LegalRepresentatives) => {
      const { id } = representative;
      acc[id] = { id };
      return acc;
    },
    {},
  );

  return {
    legalRepresentatives: Object.values(groupedLegalRepresentatives),
  };
}

export default groupLegalRepresentativesById;
