import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';

import { GetBankContext } from '../../context/GetBankContext';
import { Account, Bank } from '../../Interfaces/getBank';
import { digestAccount } from '../../utils/digestAccount';
import Panel from './styles';

function GetBankAccordion({ bank }: { bank: Bank }) {
  const state = GetBankContext.useState();
  const dispatch = GetBankContext.useDispatch();

  const [cbuHashIndex, setCbuHashIndex] = useState<{ [key: string]: string }>({});

  const isSelectAll = useMemo(() => {
    const validAccounts = bank.accounts?.filter(({ esCtaPropiaValida }) => esCtaPropiaValida);
    if (validAccounts?.length !== bank.accounts?.length) {
      return false;
    }
    return validAccounts?.every(({ cbu }) => state.selectedAccounts[cbuHashIndex[cbu]]);
  }, [bank.accounts, state.selectedAccounts, cbuHashIndex]);

  const hasValidAccount = useMemo(() => {
    const hasAtLeastOneValid = bank.accounts?.some(({ esCtaPropiaValida }) => esCtaPropiaValida);

    return hasAtLeastOneValid;
  }, [bank.accounts]);

  const handleAccountCheckboxChange = (account: Account) => {
    digestAccount(account).then(hash => {
      dispatch({ type: 'toggleSelectedAccount', payload: hash });
    });
  };

  const handleSelectAllCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    Promise.all(bank.accounts.filter(({ esCtaPropiaValida }) => esCtaPropiaValida).map(account => digestAccount(account))) //
      .then(hashList => {
        const payload = Object.fromEntries(hashList.map(hash => [hash, isChecked]));
        dispatch({ type: 'setMultipleSelectedAccounts', payload });
      });
  };

  useEffect(() => {
    (async () => {
      const cbuHashEntries = await Promise.all(bank.accounts.map(account => digestAccount(account).then(hash => [account.cbu, hash])));
      const data = Object.fromEntries(cbuHashEntries);
      setCbuHashIndex(data);
    })();
  }, [bank.accounts]);

  const selectAccount = bank.accounts?.map(account => state.selectedAccounts[cbuHashIndex[account.cbu]]);
  const isIndeterminate = selectAccount?.some(element => element === true);

  return (
    <Panel>
      <Box className='checkbox-position'>
        {hasValidAccount ? (
          <Checkbox
            isChecked={isSelectAll}
            isIndeterminate={isSelectAll ? false : isIndeterminate}
            onChange={handleSelectAllCheckBoxChange}
            id={bank.denominacion}
          />
        ) : (
          <Box className='tooltip '>
            <Checkbox isDisabled isChecked={false} />
            <span className='tooltip-text tooltip-header'>
              Todas las cuentas de este banco ya tienen un CUIT asociado para operar en Interbanking
            </span>
          </Box>
        )}
      </Box>
      <AccordionItem
        bg='white'
        border='#BEC6DC'
        borderRadius='10px'
        boxShadow='none'
        className={`${hasValidAccount ? '' : 'accordion-disabled'}`}
      >
        <AccordionButton
          bg='white'
          boxShadow='none'
          borderColor='gray.100'
          borderRadius='10px'
          borderBottom='none'
          className={`${hasValidAccount ? '' : 'accordion-disabled'} ${
            isSelectAll || isIndeterminate ? 'accordion-selected accordion-selected-expanded' : 'accordion-collapsed'
          }`}
        >
          <Box className='accordion-header'>
            <Box className='box-avatar-checkbox'>
              <Avatar
                ml='72px'
                backgroundColor='#2172DC'
                size='sm'
                color='white'
                src={`${process.env.URL_BANK_LOGOS}logo${bank.codBcoBcra}.png`}
                name={`${bank.denominacion} ${bank.denominacion}`}
              />
              <Text ml='15px'>
                <span>{bank.denominacion}</span>
              </Text>
            </Box>
            <Box className='tooltip-suscription'>
              {bank.tipoSuscripcion === 'física' ? (
                <Tooltip
                  hasArrow
                  placement='bottom'
                  label='Para finalizar el alta de este banco deberás descargar los formularios que te brindaremos y presentarlos en una sucursal. Deben estar firmados por quienes representan a tu empresa'
                >
                  <Box className='position-meta'>Suscripción física</Box>
                </Tooltip>
              ) : null}
            </Box>
          </Box>
          <ChevronDown className='blue' />
        </AccordionButton>

        <AccordionPanel bg='white' borderRadius='10px'>
          <Box mt='-13px' ml='-24px' className={`${isSelectAll || isIndeterminate ? 'table-selected' : null} my-tab-DNDA`}>
            <TableContainer className='tabUpper'>
              <Table variant='unstyled'>
                <Thead className=''>
                  <Tr className='acco'>
                    <Th />

                    <Th className='header-tab'>Tipo de cuenta</Th>
                    <Th className='header-tab'>Moneda</Th>
                    <Th className='header-tab'>CBU</Th>
                  </Tr>
                </Thead>

                {bank.accounts?.map(account => {
                  const isValidAccount = account.esCtaPropiaValida;

                  return (
                    <Tbody key={bank.denominacion} className={`${isValidAccount ? '' : 'tbody-disabled'}`}>
                      <Tr className={`${state.selectedAccounts[cbuHashIndex[account.cbu]] ? 'acco' : ''}`}>
                        <Td className='MaxWidpercent'>
                          {isValidAccount ? (
                            <Checkbox
                              id={account.cbu}
                              isChecked={state.selectedAccounts[cbuHashIndex[account.cbu]] === true}
                              onChange={() => {
                                handleAccountCheckboxChange(account);
                              }}
                            />
                          ) : (
                            <Box className='tooltip'>
                              <Checkbox isDisabled id={account.cbu} isChecked={false} />
                              <span className='tooltip-text'>
                                Esta cuenta ya está asociada a otro CUIT <br />
                                para operar en Interbanking
                              </span>
                            </Box>
                          )}
                        </Td>

                        <Td>{`${account.tipo === 'CC' ? 'Cuenta Corriente' : 'Caja de Ahorro'}`}</Td>
                        <Td>{`${account.moneda === '$' ? 'ARS' : 'USD'}`}</Td>
                        <Td>{account.cbu}</Td>
                      </Tr>
                    </Tbody>
                  );
                })}
              </Table>
            </TableContainer>
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Panel>
  );
}

export default GetBankAccordion;
