import { AccountWithMeta, Bank } from '../../Interfaces/getBank';
import { groupAccountsByBank } from '../getBank/groupAccountsByField';

export type GroupByTipoSuscripcionReturn = {
  tipoSuscripcion: AccountWithMeta['meta']['tipoSuscripcion'];
  data: Bank[];
}[];

export function groupAccountsByTipoSuscripcion(collection: AccountWithMeta[]): GroupByTipoSuscripcionReturn {
  const sortedCollection = collection.sort((a, b) => {
    return a.denominacion.localeCompare(b.denominacion);
  });

  const grouped = Object.values(
    sortedCollection.reduce((acc, item) => {
      const { tipoSuscripcion } = item.meta;
      const group = acc[tipoSuscripcion];

      acc[tipoSuscripcion] = {
        tipoSuscripcion,
        data: [...(group?.data ?? []), item],
      };

      return acc;
    }, {} as { tipoSuscripcion: AccountWithMeta['meta']['tipoSuscripcion']; data: AccountWithMeta[] }[]) ?? {},
  );

  const sortedGroups = grouped.sort((a, b) => {
    if (a.tipoSuscripcion === 'física' && b.tipoSuscripcion !== 'física') {
      return -1;
    }
    if (a.tipoSuscripcion !== 'física' && b.tipoSuscripcion === 'física') {
      return 1;
    }
    return a.tipoSuscripcion.localeCompare(b.tipoSuscripcion);
  });

  return sortedGroups.map(({ tipoSuscripcion, data }) => ({
    tipoSuscripcion,
    data: groupAccountsByBank(data),
  }));
}

export default groupAccountsByTipoSuscripcion;
