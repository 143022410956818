import styled from 'styled-components';

const DrawerPanel = styled.div`
  .css-3p6uhs {
    border: 0;
    margin-bottom: 25px;
    color: #3f4759;
    margin-bottom: 25px;
    background-color: transparent !important;
    cursor: pointer;
  }
  .css-onkibi {
    margin-top: 10px;
    width: 12x;
    height: 12px;
    margin-right: 10px;
  }
  .css-v0iyfl {
    padding-left: 0;
  }
`;

export default DrawerPanel;
