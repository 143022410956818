import styled from 'styled-components';

const FilterPanel = styled.div`
  .css-jkx69u {
    top: -6.5px;
  }
  .css-nck120 {
    position: static !important;
  }

  /*logos bancos*/

  .css-9mssec {
    position: static !important;
  }

  /*texto checkbox*/

  .css-1o7nmjb {
    margin-inline-start: 1rem !important;
  }

  /* label input*/

  .css-1y0e7gb {
    display: grid !important;
  }

  /* estolo propio de componente*/

  .pd-top {
    padding-top: 7px;
  }

  .mr-left {
    left: 95px;
    position: absolute;
  }
  .display-flex {
    display: flex;
  }

  .pd {
    padding: 12px;
  }
  .filter-containerDNBA {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    border: 1px solid #bec6dc;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .spani-DNBA {
    font-weight: bold;
    font-size: 14px;
  }

  .modal-content {
    height: 308px;
    width: 284px;
    position: relative;
    background: white;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    z-index: 100;
  }

  .modal-height {
    height: 268px;
  }

  .title-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 52px;
    padding: 0 6px 0 16px;
    align-items: center;
    align-self: stretch;
  }

  .style-title {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  .close-button {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
  }

  .button-text-blue {
    height: 40px !important;
    width: 100% !important;
    color: var(--primary-100, #2172dc) !important;
    text-align: center !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    letter-spacing: 0.1px !important;
    text-decoration-line: underline !important;
  }

  .button-text-white {
    width: 100% !important;
    text-align: center !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 13px !important;
    height: 40px !important;
    border: 1px solid var(--primary-100, #2172dc) !important;
  }

  .input-content {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 24px 6px 16px;
  }

  .checkbox-content {
    padding: 6px 24px 6px 30px;
    height: 40px;
  }

  .label-style {
    color: var(--neutral-90, #74777f);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.1px;
    margin-top: 0px;
  }

  .not-found-text {
    color: var(--neutral-110, var(--color-neutral-110, #44474f));
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin: 0;
  }

  .scroll {
    overflow: auto;
  }

  /* Para WebKit (Chrome, Safari) */

  ::-webkit-scrollbar {
    background-color: #dae2f9;
    border-radius: 2px;
    position: fixed;
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #565e71;
    border-radius: 2px;
  }

  /* Para Firefox */

  ::-moz-scrollbar {
    background-color: #dae2f9;
    border-radius: 2px;
    position: fixed;
    width: 4px;
  }

  ::-moz-scrollbar-thumb {
    background-color: #565e71;
    border-radius: 2px;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: grid;
  }
  .container-checkbox-DNBA {
    height: 86px !important;
  }
`;

export default FilterPanel;
