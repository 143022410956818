/* eslint-disable import/extensions */
import './styled/fonts';
import '@fontsource/red-hat-display/400.css';
import '@fontsource/red-hat-display/500.css';
import '@fontsource/red-hat-display/700.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender, theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { AccordionChakra } from '@wow/accordion';
import { AlertChakra } from '@wow/alert';
import { AvatarChakra } from '@wow/avatar';
import { BadgeChakra } from '@wow/badge';
import { BreadcrumbChakra } from '@wow/breadcrumb';
import { ButtonChakra as Button } from '@wow/button';
import { CheckboxChakra } from '@wow/checkbox';
import { CloseButtonChakra as CloseButton } from '@wow/close-button';
import { DrawerChakra } from '@wow/drawer';
import { InputChakra } from '@wow/input';
import { SkeletonChakra } from '@wow/progress-indicator';
// eslint-disable-next-line import/no-unresolved
import { themeChakra } from '@wow/themes';
import { ToastComponent as Toast } from '@wow/toast';
import { TooltipChakra } from '@wow/tooltip';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';
import { GetBankContext } from './context/GetBankContext';
import StepFormContext from './context/StepFormContext';
import SubscriptionProvider from './context/SuscriptionProvider';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  ...ThemeData,
  textStyles: {
    ...themeChakra.textStyles,
  },
  components: {
    Button,
    Breadcrumb: BreadcrumbChakra,
    Accordion: AccordionChakra,
    Tooltip: TooltipChakra,
    Badge: BadgeChakra,
    Skeleton: SkeletonChakra,
    Checkbox: CheckboxChakra,
    Avatar: AvatarChakra,
    Alert: AlertChakra,
    Input: InputChakra,
    Drawer: DrawerChakra,
    Toast,
    CloseButton,
  },
  shadows: {
    ...themeChakra.shadows,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <StepFormContext.Provider>
            <GetBankContext.Provider>
              <SubscriptionProvider>
                <Fonts />
                <App />
                <ToastContainer />
              </SubscriptionProvider>
            </GetBankContext.Provider>
          </StepFormContext.Provider>
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
