import { useQuery } from 'react-query';

import { getBank } from '../services/api';

type FetchedData = Awaited<ReturnType<typeof getBank>>['data'];

export function useBanks<TData = FetchedData>(
  { operador, idEntidad }: { operador: number; idEntidad: string },
  { select }: { select?: (d: FetchedData) => TData } = {},
) {
  return useQuery({
    queryKey: ['cuentasSinAlta', operador, idEntidad],
    queryFn: () => getBank(operador, idEntidad).then(res => res.data),
    select,
  });
}

export default useBanks;
