/**
 * Digest string data to hexadecimal
 *
 * **IMPORTANT!**: don't use SHA-1 in cryptographic applications
 *
 * @param algo - hashing algorithm to use (sha-1, sha-256, sha-384, sha-512)
 * @param data - string data to hashing
 * @returns data hash as hexadecimal
 */
export async function digestString(algo: AlgorithmIdentifier, data: string) {
  const dataEncoded = new TextEncoder().encode(data);
  // BEGIN-NOSCAN
  const hashBuffer = await crypto.subtle.digest(algo, dataEncoded);
  // END-NOSCAN
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray
    .map(b => b.toString(16).padStart(2, '0')) //
    .join('');
}

export default digestString;
