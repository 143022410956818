/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const Panel = styled.div`
  padding-right: 30px;
  height: 100%;
  position: relative;
  margin-right: 67px;

  .planContratado {
    font-family: Inter, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01563rem;
    font-weight: bold;
  }
  .box-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export default Panel;
