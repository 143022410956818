import styled from 'styled-components';

const Panel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-container {
    text-align: center;
    margin: 0;
  }
`;
export default Panel;
