/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-no-useless-fragment */
import { Navigate } from 'react-router-dom';

import StepFormContext from '../../context/StepFormContext';

interface RequireStepProps {
  pathBase: string;
  step: number;
  children: React.ReactNode;
}
function DigitalesRequiredStep({ pathBase, step, children }: RequireStepProps) {
  const state = StepFormContext.useState();
  const dispatch = StepFormContext.useDispatch();

  if (state.currentStep < step) {
    dispatch({ type: 'restartStep' });
    return (
      <Navigate
        to={{
          pathname: pathBase,
        }}
      />
    );
  }
  return <>{children}</>;
}
export default DigitalesRequiredStep;
