import { Box } from '@chakra-ui/react';
import { useWizard, WizardComponent } from '@wow/wizard';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { applicationServiceMockNew } from '../__mocks__/applicationServiceMockNew';
import Breadcrumb from '../components/breadcrumb';
import Header from '../components/header';
import Home from '../components/home';
import Loader from '../components/loading';
import SubscriptionList from '../components/subscription-list';
import ErrorPendingRequest from '../components/subscription-list/ErrorPendingRequest';
import useApplications from '../hooks/useApplications';
import { Application } from '../Interfaces/pendingRequests';
import WizardSteps from '../utils/wizardSteps';
import Panel from './styles';

function Project() {
  const title = 'Alta de bancos';
  const subtitle = 'Agregá bancos y revisá tus solicitudes en curso. Podés ver las finalizadas y el estado de tus bancos en ';
  const subtitleButton = 'Plan contratado.';
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();
  const [applications, setApplications] = useState<Application[]>(applicationServiceMockNew.solicitudes);

  const { data: applicationData, isLoading: applicationIsLoading, refetch } = useApplications();

  useEffect(() => {
    if (localStorage.getItem('digital-wizard') === null) {
      setInitialized(true);
    } else {
      setApplications(applicationData);
    }
  }, [initialized, applicationData]);

  const handleLocalStorageWizard = () => {
    refetch();
    localStorage.setItem('digital-wizard', 'true');
  };

  if (applicationIsLoading) {
    return <Loader />;
  }
  if (!applicationData) {
    return (
      <>
        <Breadcrumb />
        <Header title={title} subtitle={subtitle} subtitleButton={subtitleButton} showButton={false} />
        <ErrorPendingRequest rlsRefetch={refetch} />
        <Outlet />
      </>
    );
  }
  return (
    <Panel>
      <Breadcrumb />
      {(applicationData.length > 0 && !initialized) || (initialized && currentStep !== 5) ? (
        <SubscriptionList
          titleHome={title}
          subtitleHome={subtitle}
          subtitleButton={subtitleButton}
          showButton={applicationData.length > 0 || initialized}
          applications={applications}
        />
      ) : (
        <Box p={initialized ? '30px' : null} id='mainBox' mr={initialized ? '40px' : null}>
          <Home title={title} subtitle={subtitle} subtitleButton={subtitleButton} showButton={false} />
        </Box>
      )}
      <Outlet />
      <WizardComponent
        id='digital-wizard'
        steps={WizardSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        initialized={initialized}
        setInitialized={setInitialized}
        offset={20}
        initialLabel='Comenzar'
        finishLabel='Finalizar'
        onFinish={handleLocalStorageWizard}
        onPrevious={() => null}
        onNext={() => null}
      />
    </Panel>
  );
}

export default Project;
