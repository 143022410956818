import styled from 'styled-components';

import infoImage from '../../assets/img/alert.svg';
import deleteImage from '../../assets/img/delete.svg';
import sendImage from '../../assets/img/send.svg';
import image from '../../assets/img/zoom-in.svg';

const Panel = styled.div`
  .css-quz8y2 {
    border-radius: 0px;
  }
  .css-bd510j[aria-expanded='true']:hover,
  .css-bd510j[data-expanded]:hover,
  .css-bd510j[aria-expanded='true'][data-hover],
  .css-bd510j[data-expanded][data-hover] {
    background-color: #d6e2ff;
  }
  .css-bd510j[aria-expanded='true'],
  .css-bd510j[data-expanded] {
    border-bottom: 1px solid rgb(199, 198, 202);
    border-right: 1px solid rgb(199, 198, 202);
  }
  .css-an768r {
    padding: 0px !important;
  }
  .subsBoxTitle {
    border-bottom: solid 1px;
    background-color: rgb(214, 226, 255);
    border-color: #c7c6ca;
    height: 70px;
    padding: 8px 16px 8px 16px;
  }
  .boxItem {
    display: flex;
    background-color: #eff0fa;
    height: 52px;
    border-bottom: solid 1px;
    border-right: solid 1px;
    border-left: solid 1px;
    border-color: #c7c6ca;
    justify-content: space-between;
    align-items: center;
  }
  .contentBox {
    display: flex;
    background-color: white;
    height: 64px;
    border-bottom: solid 1px;
    border-right: solid 1px;
    border-left: solid 1px;
    border-color: #c7c6ca;
    justify-content: space-between;
    align-items: center;
  }
  .contentBoxRl {
    display: flex;
    background-color: white;
    height: 68px;
    border-bottom: solid 1px;
    border-right: solid 1px;
    border-left: solid 1px;
    border-color: #c7c6ca;
    justify-content: space-between;
    align-items: center;
  }
  .flexItem {
    width: 588px;
    display: flex;
  }
  .detail {
    background-image: url(${image});
    height: 20px;
    width: 20px;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -40px;
    cursor: pointer;
  }
  .send {
    background-image: url(${sendImage});
    height: 20px;
    width: 20px;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: -40px;
    cursor: pointer;
  }
  .send[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
  .avatar {
    margin: 15px -10px 0 20px;
  }
  .boxContainer {
  }
  #applications-accordion {
    padding: 5px;
  }
  .warning {
    margin-right: 50px;
    display: flex;
    border: solid 1px;
    background-color: #ffeedb;
    border-color: #ffddb4;
    height: 52px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .alertIcon {
    color: white;
    margin: 12px;
  }
  .boxIcon {
    background-color: #e49c35;
    display: flex;
    align-items: center;
    margin-right: 17px;
    width: 48px;
    border-radius: 8px 0 0 8px;
  }
  .backButton {
    width: 1000px;
  }
  .boxError {
    text-align: center;
  }
  .delete {
    position: absolute;
    right: 560px;
    margin-top: -10px;
    height: 20px;
    background-image: url(${deleteImage});
    height: 20px;
    width: 20px;
    border: 0;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    margin-right: -440px;
  }
  .badge {
    position: absolute;
    width: 125px;
    justify-content: flex-end;
    display: flex;
    font-size: 10px;
    right: 170px;
    border-radius: 12px;
    color: rgb(133, 83, 0);
    background: rgb(255, 238, 219);
    align-content: center;
    align-items: center;
    background-image: url(${infoImage});
    background-position: 9px 5px;
    background-size: 14px !important;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    background-repeat: no-repeat;
    padding-right: 8px;
    height: 23px;
  }
  .badgePending {
    position: absolute;
    justify-content: flex-end;
    display: flex;
    font-size: 10px;
    right: 170px;
    border-radius: 12px;
    color: #855300;
    background: #ffeedb;
    align-content: center;
    align-items: center;
    background-position: 9px 5px;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
    background-repeat: no-repeat;
    padding-right: 8px;
    padding-left: 8px;
    height: 22px;
    margin-top: -10px;
  }
  .css-4abrhy {
    all: unset;
    cursor: pointer;
  }

  .tooltip-body-desc {
    margin-bottom: -5px;
  }
  .css-bd510j {
    border-bottom: 1px solid rgb(199, 198, 202);
    border-right: 1px solid rgb(199, 198, 202);
    border-top: 1px solid rgb(199, 198, 202);
    border-left: 1px solid rgb(199, 198, 202);
  }
  .buttonMargin {
    position: absolute;
    height: 50px;
    right: 55px;
  }
  .blue {
    color: #2172dc;
  }
`;
export default Panel;
