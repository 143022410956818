// eslint-disable-next-line import/no-unresolved
import { ErrorContentComponent } from '@wow/error-content';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import DigitalesRequiredStep from './components/DigitalesRequiredStep';
import GetBank from './components/getBank';
import SubscriptionSummary from './components/subscription-summary';
import Project from './layouts/project';
import WithOperatorsPermissions from './utils/WithOperatorsPermissions';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path='*'
          element={
            <ErrorContentComponent
              ErrorContentProps={{
                isRouter: true,
                titleText: 'No podemos mostrar la página',
                accessError: 'content',
                bodyText: 'Puede que el enlace haya cambiado o no exista más. Revisá la URL y volvé a intentar más tarde.',
                linkText: 'Ir al Inicio',
                redirectTo: '/',
              }}
            />
          }
        />
        <Route
          path='digitales'
          element={
            <WithOperatorsPermissions>
              <Project />
            </WithOperatorsPermissions>
          }
        />
        <Route
          path='digitales/bancos'
          element={
            <WithOperatorsPermissions>
              <DigitalesRequiredStep pathBase='/digitales' step={1}>
                <GetBank />
              </DigitalesRequiredStep>
            </WithOperatorsPermissions>
          }
        />
        <Route
          path='digitales/resumen-suscripcion'
          element={
            <WithOperatorsPermissions>
              <DigitalesRequiredStep pathBase='/digitales' step={2}>
                <SubscriptionSummary />
              </DigitalesRequiredStep>
            </WithOperatorsPermissions>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
