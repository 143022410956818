// eslint-disable-next-line import/no-unresolved
import { WithPermissions } from '@react-ib-mf/style-guide-ui';

import { IWithPermissionsProps } from '../../interfaces/IWithPermissions';

/**
 * The operator requires having at least one of
 * these permissions to operate.
 *
 * To obtain a reference to what each one corresponds to,
 * consult Operator section {@link docs/PERMISSION_REFERENCES.md}
 */
const PERMISSIONS_OPERATOR = [
  '8fbd331d39c1b0060b4a41c76746afb4c9430ef7e953375ccfea79cc60d26db3',
  'af314dd9352260f21c14121cde7cf36256cf7fdd52678717a1447a249fcce876',
  '3de184b7888f83d88c52ee5a93aa74f9b1c275570d34a9582515e4a9db1de403',
  '4b4174940ccdbaa135f022c6ea4b7bd4fffe135eae7c5cc820a55b24fd02ab1a',
  '9c8f5e973031da344c0ed90c5a3330102524ace93afb656986bd45d536d7cc68',
];

function WithOperatorsPermissions({ children }: Pick<IWithPermissionsProps, 'children'>) {
  return (
    <WithPermissions permission={PERMISSIONS_OPERATOR} showErrorPage>
      {children}
    </WithPermissions>
  );
}

export default WithOperatorsPermissions;
