/* eslint-disable react/no-array-index-key */
import { Avatar, Badge, Box, Button, Text, Tooltip, useDisclosure, useToast } from '@chakra-ui/react';
import { ToastComponent } from '@wow/toast';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import imageBanco from '../../../assets/img/banco-logo-general.svg';
import { STATUS_BANK_ERROR, STATUS_BANK_PROCESS, STATUS_REQUEST } from '../../../constants';
import { Bank, BankDataProps } from '../../../Interfaces/pendingRequests';
import { getForms } from '../../../services/api';
import Download from '../../btns/download';
import DrawerAccountsSubscription from '../../drawerAccountsSubscription';
import SubscriptionPanel from '../styles';

function ItemBank({ data, requestId }: BankDataProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [selectedBank, setSelectedBank] = useState({
    accounts: [{ accountType: '', accountCurrency: '', accountCbu: '' }],
    bankDenomination: '',
  });
  const disabledDownloadForm =
    (!data.digitalSubscription && data?.data.some((acc: Bank) => acc.bankStatus === 'PROCESS_STARTED')) ||
    (!data.digitalSubscription && data?.data.every((acc: Bank) => STATUS_BANK_ERROR.includes(acc.bankStatus)));
  const toast = useToast();
  const statusBankProcess = (status: string) => STATUS_BANK_PROCESS.includes(status) && !STATUS_REQUEST.includes(data.status);
  const statusBankError = (status: string) => STATUS_BANK_ERROR.includes(status);
  const allStored = (status: string) => status === 'STORED' && STATUS_REQUEST.includes(data.status);
  const [gettingForms, setGettingForms] = useState(false);
  const openDrawerForDetail = (bank: BankDataProps['data']['data'][number]) => {
    onOpen();
    setSelectedBank({
      accounts: bank.accounts.map(item => ({
        accountType: item.accountType,
        accountCurrency: item.accountCurrency,
        accountCbu: item.accountCbu,
      })),
      bankDenomination: bank.bankDenomination,
    });
  };
  const getAccountsText = (count: number) => {
    return count === 1 ? `${count} cuenta` : `${count} cuentas`;
  };

  const openToast = (status, title, description, ctaOnClick = null) => {
    toast({
      duration: 5000,
      position: 'bottom-left',
      status,
      title,
      description,
      render: props => (
        <ToastComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          isCtaLabel={status === 'error'}
          ctaLabel={status === 'error' ? 'Reintentar' : ''}
          ctaOnClick={ctaOnClick}
        />
      ),
    });
  };
  const handleGetForms = useMutation(
    async () => {
      setGettingForms(true);
      const response = await getForms(requestId);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Formularios-bancos-con-suscripcion-fisica.zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    {
      onSuccess: () => {
        openToast('success', 'Descargado OK', 'Descargaste los formularios con éxito.');
      },
      onError: () => {
        openToast('error', 'Reintentar', 'No podemos descargar los formularios.', () => handleGetForms.mutate());
      },
      onSettled: () => {
        setGettingForms(false);
      },
    },
  );
  return (
    <SubscriptionPanel>
      <Box className='boxItem'>
        <Text m='0px' p='12px' w='1053px ' textStyle='titleMdBold'>
          {' '}
          {data.digitalSubscription === false
            ? `Bancos con suscripción física (${data.data.length})`
            : `Bancos digitales (${data.data.length})`}
        </Text>
        {data.digitalSubscription === false ? (
          <Button
            id='download-forms'
            className='buttonMargin'
            leftIcon={<Download />}
            loadingText='Descargar Formularios'
            size='sm'
            data-testid='download-forms-button'
            isDisabled={disabledDownloadForm || gettingForms}
            spinnerPlacement='start'
            variant='primary-text'
            onClick={() => handleGetForms.mutate()}
          >
            Descargar Formularios
          </Button>
        ) : null}
      </Box>
      {data.data.map((item: Bank) => (
        <Box className='contentBox' key={item.bankDenomination}>
          {' '}
          <Box className='flexItem'>
            <Avatar
              className='avatar'
              id='avatar'
              backgroundColor='#2172DC'
              size='sm'
              color='white'
              name={`${item.bankDenomination}`}
              src={item.bankCodeBcra ? `${process.env.URL_BANK_LOGOS}logo${item.bankCodeBcra}.png` : imageBanco}
            />
            <Text m='0px' p='20px' textStyle='h6'>
              {item.bankDenomination}
            </Text>
          </Box>
          <Box className='flexItem'>
            <Text m='0px' p='20px' textStyle='bodyMd'>
              {getAccountsText(item.accounts.length)}
            </Text>
          </Box>
          <Box className='flexItem'>
            {(() => {
              if (statusBankProcess(item.bankStatus)) {
                return (
                  <Tooltip
                    hasArrow
                    placement='right'
                    label={
                      <Text maxWidth='250px'>
                        Tus representantes deberán confirmar la solicitud para enviarla. En suscripciones físicas, el estado no cambiará y
                        deberás presentar los formularios a los bancos.
                      </Text>
                    }
                  >
                    <Badge variant='neutral-light'>En proceso</Badge>
                  </Tooltip>
                );
              }
              if (statusBankError(item.bankStatus)) {
                return (
                  <Tooltip
                    hasArrow
                    placement='right'
                    label={
                      <Text maxWidth='250px'>
                        No pudimos enviar la solicitud a este banco. Creá una nueva solicitud para los bancos con este estado. Si el error
                        persiste, comunicate con Soporte.
                      </Text>
                    }
                  >
                    <Badge variant='error-dark'>Error</Badge>
                  </Tooltip>
                );
              }
              if (data.digitalSubscription === true && allStored(item.bankStatus)) {
                return (
                  <Tooltip
                    hasArrow
                    placement='right'
                    label={
                      <Text maxWidth='250px'>
                        El banco ya tiene tu solicitud. Si ya fue evaluada, podés ver la respuesta ingresando a <b>Administración</b>, luego
                        a <b>ABM | Configuración de datos</b>, y finalmente a <b>ABM Plan contratado</b>.
                      </Text>
                    }
                  >
                    <Badge variant='info-light'>Enviada</Badge>
                  </Tooltip>
                );
              }
              return (
                <Tooltip
                  hasArrow
                  placement='right'
                  label={
                    <Text maxWidth='250px'>
                      Tus representantes deberán confirmar la solicitud para enviarla. En suscripciones físicas, el estado no cambiará y
                      deberás presentar los formularios a los bancos.
                    </Text>
                  }
                >
                  <Badge variant='neutral-light'>En proceso</Badge>
                </Tooltip>
              );
            })()}
          </Box>
          <Box>
            <Tooltip hasArrow placement='left' label='Ver más detalle'>
              <button onClick={() => openDrawerForDetail(item)} id='detail' className='detail' />
            </Tooltip>
          </Box>
        </Box>
      ))}
      <DrawerAccountsSubscription isOpen={isOpen} onClose={onClose} btnRef={btnRef} data={selectedBank} />
    </SubscriptionPanel>
  );
}

export default ItemBank;
