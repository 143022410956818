import { useQuery } from 'react-query';

import { LegalRepresentative } from '../Interfaces/DataSubscriptionInterface';
import { getRepresentanteLegal } from '../services/api';

export function useRepresentanteLegal<TData = { legalRepresentatives?: LegalRepresentative[] }>({
  select,
  shouldFetch = true,
}: {
  select?: (d: { legalRepresentatives?: LegalRepresentative[] }) => TData;
  shouldFetch?: boolean;
} = {}) {
  return useQuery({
    queryKey: ['getRepresentanteLegal'],
    queryFn: () => {
      if (shouldFetch) {
        return getRepresentanteLegal().then(res => res.data.data);
      }
      return undefined;
    },
    select,
  });
}

export default useRepresentanteLegal;
