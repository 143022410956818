import { BreadcrumbComponent } from '@wow/breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';

import { CONST_BREADCRUMB } from '../../constants';

function Bradcrumb() {
  const location = useLocation();
  const navigate = useNavigate();
  const routesBreadcrumb = [
    {
      path: '/digitales',
      breadcrumb: [
        ...CONST_BREADCRUMB,
        {
          key: 'bancos',
          id: 'bancos',
          label: 'Alta de bancos',
          link: '',
          isCurrentPage: true,
        },
      ],
    },
    {
      path: '/digitales/bancos',
      breadcrumb: [
        ...CONST_BREADCRUMB,
        {
          key: 'bancos',
          id: 'bancos',
          label: 'Alta de bancos',
          link: '/digitales',
          onClick: e => {
            e.preventDefault();
            navigate('/digitales');
          },
        },
        {
          key: 'agregarbancos',
          id: 'agregarbancos',
          label: 'Agregar bancos',
          link: '',
          isCurrentPage: true,
        },
      ],
    },
    {
      path: '/digitales/resumen-suscripcion',
      breadcrumb: [
        ...CONST_BREADCRUMB,
        {
          key: 'bancos',
          id: 'bancos',
          label: 'Alta de bancos',
          link: '/digitales',
          onClick: e => {
            e.preventDefault();
            navigate('/digitales');
          },
        },
        {
          key: 'agregarbancos',
          id: 'agregarbancos',
          label: 'Agregar bancos',
          link: '',
          isCurrentPage: true,
        },
      ],
    },
  ];
  const matchingRoute = routesBreadcrumb.find(route => route?.path === location?.pathname);
  return <BreadcrumbComponent mt='4px' items={matchingRoute ? matchingRoute.breadcrumb : CONST_BREADCRUMB} />;
}

export default Bradcrumb;
