import { AccountWithMeta } from '../../Interfaces/getBank';
import { digestAccount } from '../../utils/digestAccount';

export async function filterAccountsBySelected({
  accounts,
  selectedAccounts,
}: {
  accounts: AccountWithMeta[];
  selectedAccounts: { [key: string]: boolean };
}) {
  return (await Promise.all(accounts.map(account => digestAccount(account).then(hash => ({ hash, account })))))
    .filter(({ hash }) => selectedAccounts[hash])
    .map(({ account }) => account);
}

export default filterAccountsBySelected;
