/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

import Image from '../../assets/img/nothinghere.svg';

const ErrorPanel = styled.div`
  .nothingHereImg {
    background-image: url(${Image});
    height: 76.1px;
    width: 76.1px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
    margin-top: 50px;
  }
`;

export default ErrorPanel;
