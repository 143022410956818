import FlowButtons from '../flowButtons';
import Message from '../message';

function ErrorEmptyBank() {
  return (
    <>
      <Message
        icon='success'
        title='¡Bien hecho!'
        subtitle='Todos los bancos con los que opera tu empresa ya están dados de alta.'
        button={{
          buttonLabel: 'Volver al menú',
          buttonAction: '/digitales',
        }}
      />
      <FlowButtons
        data={{
          volver: '/digitales',
          isDisabled: true,
          text: 'Elegir',
          notStep: true,
        }}
      />
    </>
  );
}

export default ErrorEmptyBank;
