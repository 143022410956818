import { AccordionButton, AccordionItem, AccordionPanel, Box, Text, Tooltip } from '@chakra-ui/react';
import { ChevronDown } from 'react-feather';

import { Bank, BankData, LegalRepresentative, SubsProp } from '../../../Interfaces/pendingRequests';
import ItemBank from '../itemBank';
import ItemRl from '../itemRl';
import Panel from '../styles';

function ApplicationList({ data }: SubsProp) {
  const bankData: BankData[] = data.banks.reduce((result, bank) => {
    const existingSubscription = result.find(subscription => subscription.digitalSubscription === bank.digitalSubscription);

    if (existingSubscription) {
      existingSubscription.data.push({
        bankDenomination: bank.bankDenomination,
        accounts: bank.accounts,
        bankCodeBcra: bank.bankCodeBcra,
        bankStatus: bank.bankStatus,
      });
    } else {
      result.push({
        digitalSubscription: bank.digitalSubscription,
        status: data.status,
        data: [
          {
            bankDenomination: bank.bankDenomination,
            accounts: bank.accounts,
            bankCodeBcra: bank.bankCodeBcra,
            bankStatus: bank.bankStatus,
          },
        ],
      });
    }

    return result;
  }, []);
  const rls: LegalRepresentative[] = data.legalRepresentatives;

  bankData.sort(({ digitalSubscription: a }, { digitalSubscription: b }) => String(a).localeCompare(String(b)));

  const badgeWarning = data?.banks.some(
    (acc: Bank) => acc.digitalSubscription === false && !(acc.bankStatus === 'RULE_FAILED' || acc.bankStatus === 'RETRY_FAIL'),
  );

  const isPhysicalSubscription = data.banks.every((acc: Bank) => acc.digitalSubscription === false);

  const isDigitalAllError = data.banks.every(
    (acc: Bank) => acc.digitalSubscription === true && (acc.bankStatus === 'RULE_FAILED' || acc.bankStatus === 'RETRY_FAIL'),
  );

  const formattedDate = new Date(data.createdAt).toLocaleDateString('es-ES', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return (
    <Panel>
      <AccordionItem bg='white' border='#BEC6DC' borderRadius='10px'>
        <AccordionButton bg='white' border='#BEC6DC solid 1px' borderRadius='8px 8px 0 0'>
          <Box>
            <Text m='0px' textStyle='h5Bold' textAlign='left'>
              {' '}
              Solicitud{' '}
            </Text>
            <Text m='0px' textStyle='bodyMd'>{`Creada el ${formattedDate}`}</Text>
          </Box>

          {badgeWarning ? (
            <Tooltip
              hasArrow
              data-testid='badgeWarning'
              placement='bottom'
              label='Para finalizar el alta de este banco deberás descargar los formularios que te brindaremos. Quienes representan a tu empresa deberán firmar la carta de ratificación y presentarla.'
            >
              <Box className='badge'>
                {' '}
                <Text textStyle='labelXsBold'>Suscripción física</Text>
              </Box>
            </Tooltip>
          ) : null}
          <ChevronDown className='blue' />
        </AccordionButton>

        <AccordionPanel p='0' bg='white' borderRadius='10px'>
          {bankData?.map((elem: BankData) => (
            <ItemBank key={`${elem.digitalSubscription}`} data={elem} requestId={data.requestId} />
          ))}
          {!isPhysicalSubscription && (
            <Box id='legal-representative'>
              <Box className='boxItem'>
                <Text m='0px' p='12px' w='1053px' textStyle='titleMdBold'>
                  {' '}
                  {`Representantes legales / personas apoderadas (${data.legalRepresentatives.length})`}
                </Text>
              </Box>
              {rls.map((item: LegalRepresentative) => (
                <ItemRl key={item.id} data={item} statusBanks={isDigitalAllError} />
              ))}
            </Box>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Panel>
  );
}

export default ApplicationList;
