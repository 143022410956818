import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';

import FlowButtons from '../flowButtons';

interface Refetched {
  rlsRefetch: (...args: any[]) => void;
}

function ErrorServiceBank({ rlsRefetch }: Refetched) {
  const ErrorContentPropsData: ErrorContentProps = {
    titleText: 'La información no esta disponible en este momento',
    bodyText: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
    linkText: 'Reintentar',
    callbackAction: () => rlsRefetch(),
    accessError: 'content',
    isRouter: false,
  };

  return (
    <>
      <style>{`.error-page .bodyText {font-size: 1rem !important; font-family: Inter, sans-serif !important; font-weight: 400 !important;}`}</style>
      <ErrorContentComponent ErrorContentProps={ErrorContentPropsData} />
      <FlowButtons
        data={{
          volver: '/digitales',
          isDisabled: true,
          text: 'Elegir',
        }}
      />
    </>
  );
}

export default ErrorServiceBank;
