/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useReducer } from 'react';

import SubscriptionReducer, { ADD_RLS } from './SubscriptionReducer';

type SubscriptionType = {
  rlsSubscription: [];
  addRls: (arg0: any) => void;
};
export const Subscription = createContext<SubscriptionType | undefined>(undefined);

function SubscriptionProvider({ children }) {
  const initialState = {
    rlsSubscription: [],
  };
  const [state, dispatch] = useReducer(SubscriptionReducer, initialState);

  const addRls = (rls: any) => {
    dispatch({
      type: ADD_RLS,
      payload: rls,
    });
  };
  return (
    <Subscription.Provider
      value={{
        rlsSubscription: state.rlsSubscription,
        addRls,
      }}
    >
      {children}
    </Subscription.Provider>
  );
}

export default SubscriptionProvider;
